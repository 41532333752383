import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Container, Typography, List, ListItem, ListItemText, Paper } from "@material-ui/core";
// import { Close, GetApp } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
//import { baseURL } from "../globals";
//import VideoPlayer from "../components/Player";
//import ReactTable from "react-table";
// import "react-table/react-table.css";

//const axios = require("axios");
//const FileSaver = require("file-saver");
// const moment = require('moment')

const HomeAdmin = (props) => {
  const { user } = useAuth0();
  const dfe = (props.user || {}).selDfE;
  console.log("dfe", dfe);
  console.log("user", user);
  return (
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Container>
        <Typography variant="h6" color="secondary" align="center" gutterBottom>
          Improving Writing Home Page {dfe}
        </Typography>
        <Typography align="center" paragraph>{((props.user || {}).School || {}).schoolName || ''} ({dfe})</Typography>
        <Typography paragraph>What would you like to do today?</Typography>
        <Paper elevation={4}>
        <List dense>
          <ListItem divider button component={NavLink} to={"/candidates/edit"}>
            <ListItemText primary="Upload a class"  />
            </ListItem>
            <ListItem divider button component={NavLink} to={"/teacher/assignments"}>
            <ListItemText primary="Set a task"  />
            </ListItem>
            <ListItem button component={NavLink} to={"/teacher/progress"}>
            <ListItemText primary="Check your students' progress"  />
            </ListItem>
        </List>
        </Paper>
      </Container>
    </Container>
  );
};

export default HomeAdmin;
