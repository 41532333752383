
import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Container, Typography } from '@material-ui/core'
// import { School, Videocam } from '@material-ui/icons'
// import { Chart } from "react-google-charts";
// import { NavLink } from "react-router-dom";
import { baseURL } from './../globals'

const axios = require("axios")

const Leaderboard = props => {
  const { user, getTokenSilently } = useAuth0();
  const [Leaderboard, setLeaderboard] = useState(null)
  console.log('user', user)
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/sm2/getLeaderboard",
        baseURL,
        method: "post",
        data: { },
        headers: {
          Authorization: token,
        },
      }).then((result) => setLeaderboard(result.data));
    })
  }, [getTokenSilently])

    //if (summary === null) return <span />
    console.log('Leaderboard', Leaderboard)
  return (
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
        <Typography variant="h6" color="secondary" align="center" paragraph>Leaderboard</Typography>
        <ul>
            <li>Streaks
                <ul>
                    <li>max no. of consecutive days doing questions</li>
                    <li>Rank order in school</li>
                    <li>Rank order nationally</li>
                </ul>
            </li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </Container>
  )
}

export default Leaderboard;