import React, { useEffect, useState } from "react";
import {
  Container,
} from "@material-ui/core";
// import { MARKS } from "@contentful/rich-text-types";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { baseURL } from "./../globals";

const axios = require("axios");
/*const contentful = require("contentful");
const client = contentful.createClient({
  space: "rsa635xpem70",
  accessToken: "eZ033XZhTBlLjJYHG4lGYElBsgUPntcD0jLEkTE63Lg",
});*/

//let tmpItems = []
const DBUpdate = (props) => {
    const [msg, setMsg] = useState('Working...')
    useEffect(() => {
        axios({
            url: '/noauth/dbUpdate',
            baseURL,
            method: 'post',
            data: {},
        })
        .then(result => setMsg('Done'))
    }, [])
    return (<Container fluid="true" maxWidth="md" style={{ margin: '100px auto 0 auto' }}>{msg}</Container>)
}

export default DBUpdate