import React, { Fragment } from "react";
import {
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction, Chip
} from "@material-ui/core";
import { Check } from "@material-ui/icons";

const SessionSummary = (props) => {
    console.log('SessionSummary', props)
    const responses = props.responses
    return (
        <List>
        <ListItem>
          <ListItemText
            primary="Assessment is finished"
            secondary={`Score: ${
              responses.filter((r) => r.isCorrect === true).length
            } / ${responses.length}`}
          />
        </ListItem>
        {responses.length === 0 && (
          <ListItem>
            <ListItemText primary="No Responses" />
          </ListItem>
        )}
        {responses.map((response, q) => (
          <Fragment key={`resp_${q}`}>
            <Divider />
            <ListItem style={{ backgroundColor: (response.isCorrect ? '#ccffcc' : '#ffd6cc' )}}>
              <ListItemText
                primary={`${q + 1}. ${response.question.name} ${
                  response.question.questionText || ''
                }`}
                                    />
              {/*<ListItemSecondaryAction>
                  <Chip label={response.topicName} size="small" color="secondary" style={{ margin: '0 10px 0 0' }} />              
              </ListItemSecondaryAction>*/}
            </ListItem>
            <ListItem dense style={{ backgroundColor: (response.isCorrect ? '#ccffcc' : '#ffd6cc' )}}>
              <Grid container justify="space-around" alignItems="stretch" direction={window.innerWidth < 700 ? "column" : "row"} xstyle={{ margin: "50px 0 50px 0" }}>
                {response.question.mcOptions.map((option, i) => {
                  let backgroundColor = `#3f51b5`
                  //if (option[0] === "*") backgroundColor = `green`;
                  if (response.choice.includes(option)) {
                    backgroundColor = `#3f1f71`
                  }
                  /*if (option[0] !== "*" && response.choice.includes(option)) {
                    backgroundColor = `red`
                  }*/
                  return (
                    <Grid item xs key={`resp_${response._id}_${i}`} style={{ padding: "10px 10px 10px 10px" }}>
                      <Chip size="small" label={option.replace(/^\*/, '')} style={{ backgroundColor: backgroundColor, color: 'white' }} deleteIcon={option[0] === '*' ? <Check style={{ color: 'white' }} /> : <span />} onDelete={() => console.log('x')} />
                      <ListItemSecondaryAction>
                {/*<Avatar
                  style={
                    response.isCorrect
                      ? { backgroundColor: "green" }
                      : { backgroundColor: "red" }
                  }
                >
                  {response.isCorrect ? <Check /> : <Close />}
                </Avatar>*/}
              </ListItemSecondaryAction>
                    </Grid>
                  )
                })}
              </Grid>
              </ListItem>
          </Fragment>
        ))}
        {responses.length > 0 && (
          <>
            <Divider />
            <ListItem>
              <ListItemText primary="Don't worry if you got some questions wrong.&nbsp; You'll get the chance to practise those topics again in the revision section." />
            </ListItem>
          </>
        )}
      </List>
    )
}

export default SessionSummary