import React, { useEffect, useState } from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import { useAuth0 } from "../react-auth0-spa";
import { baseURL } from "../globals";
import Footer from "../components/Footer";
import HomeAdmin from "./HomeAdmin";
import HomeCandidate from "./HomeCandidate";
const axios = require("axios");

const Home = (props) => {
  const { isAuthenticated, getTokenSilently } = useAuth0();
  const [DBUser, setDBUser] = useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently().then((token) => {
        axios({
          url: "/user/getUser",
          baseURL,
          method: "post",
          data: {},
          headers: {
            Authorization: token,
          },
        }).then((result) => setDBUser(result.data));
        setToken(token);
      });
    } else {
      setDBUser({ type: "unauthenticated" });
    }
  }, [getTokenSilently, isAuthenticated]);
  if (DBUser === null) return <span />;
  console.log("Home user", DBUser);
  return (
    <>
      <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
        {DBUser.type === "admin" && <HomeAdmin user={DBUser} token={token} />}
        {"type" in DBUser === false && (
          <HomeCandidate user={DBUser} token={token} props={props} />
        )}
        {DBUser.type === "unauthenticated" && (
          <>
            <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
              <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
                <Typography>
                  Press the <strong>Login</strong> or <strong>Sign up</strong>{" "}
                  buttons to get started.
                </Typography>
              </Paper>
            </Container>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Home;
