import React, { useEffect, useState } from "react";
// import { useAuth0 } from "../react-auth0-spa";
import {
  Grid,
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";
import { Videocam, Close } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { baseURL } from "../globals";
import VideoPlayer from "../components/Player";
import "react-table/react-table.css";
import SessionSummary from "./../sm2/sessionSummary";

const axios = require("axios");
const moment = require("moment");

const HomeCandidate = (props) => {
  // console.log("HomeCandidate props", props.props);
  const [summary, setSummary] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [video, setVideo] = useState(null);
  const [lessonSummary, setLessonSummary] = useState(null);
  const [revisionSummary, setRevisionSummary] = useState(null);
  const [dueTopics, setDueTopics] = useState(null);
  useEffect(() => {
    axios({
      url: "/candidates/getCandidateSummary",
      baseURL,
      method: "post",
      data: {
        dfe: props.user.dfe,
        group: props.user.group,
      },
      headers: {
        Authorization: props.token,
      },
    }).then((result) => {
      setSummary(result.data);
      setUser(props.user);
      setToken(props.token);
    });
    axios({
      url: "/sm2/getUserDueTopics",
      baseURL,
      method: "post",
      data: {},
      headers: {
        Authorization: props.token,
      },
    }).then((result) => setDueTopics(result.data));
  }, [props.token, props.user]);
  if (summary === null || user === null || token === null) return <span />;
  console.log("user", user, props);
  console.log("summary", summary);
  const completedLessons = summary.initial.filter((r) => r.complete === true);
  console.log("completedLessons", completedLessons);
  let nextLessons = summary.initial.filter(
    (r) => r.complete === false && "dueDate" in r
  );
  const nextLesson = nextLessons.length > 0 ? nextLessons[0] : null;
  if (nextLessons.length > 1) {
    nextLessons = nextLessons.slice(1)
  }
  console.log("revisionSummary", revisionSummary);
  console.log("dueTopics", dueTopics);
  /*console.log("nextLesson", nextLesson);
  console.log('next lessons', nextLessons)*/
  const nextLessonOverdue =
    nextLesson !== null ? moment().isAfter(nextLesson.dueDate, "days") : false;
  console.log(
    "next lesson overdue?",
    (nextLesson || {}).dueDate,
    nextLessonOverdue
  );
  return (
    <>
      <Typography paragraph>
        Hi {user.firstName} {user.lastName}
      </Typography>
      <Grid container justify="space-around">
        <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
          <Typography gutterBottom color="secondary">
            Your homework:
          </Typography>
          {nextLesson === null && (
            <Typography paragraph>
              You're up-to-date with your lessons.&nbsp; Well done!
            </Typography>
          )}
          {nextLesson !== null && (
            <>
              <Typography paragraph>
                Watch the{" "}
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "inherit" }}
                  onClick={() =>
                    setVideo({
                      lesson: nextLesson._id,
                      lessonName: nextLesson.name,
                      video: nextLesson.video,
                      responses: [],
                    })
                  }
                >
                  Video for {nextLesson.name}
                </Button>
              </Typography>
              <Typography paragraph>
                Complete the{" "}
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "inherit" }}
                  component={NavLink}
                  to={`/initialSession/${(nextLesson || {})._id}`}
                >
                  Questions for {(nextLesson || {}).name}
                </Button>
              </Typography>
              <Typography paragraph>
                Deadline{" "}
                <span style={nextLessonOverdue ? { color: "red" } : {}}>
                  {moment((nextLesson || {}).dueDate, "YYYY-MM-DD").format(
                    "dddd Do MMMM YYYY"
                  )}
                </span>
                {nextLessonOverdue ? (
                  <span style={{ color: "red" }}> (overdue)</span>
                ) : (
                  <span />
                )}
                .
              </Typography>
            </>
          )}
          <Divider style={{ margin: "10px 0 15px 0" }} />
          <Typography gutterBottom color="secondary">
            Your completed lessons:
          </Typography>
          <Paper elevation={4}>
            <List dense>
              {completedLessons.length === 0 && (
                <ListItem>
                  <ListItemText primary="None" />
                </ListItem>
              )}
              {completedLessons.map((lesson, i) => (
                <ListItem
                  divider={i < completedLessons.length - 1}
                  key={`completed_${i}`}
                  label={lesson.name}
                >
                  <ListItemIcon>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setVideo({
                          lesson: lesson._id,
                          lessonName: lesson.name,
                          video: lesson.video,
                          responses: lesson.responses,
                        })
                      }
                    >
                      <Videocam />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={lesson.name} />
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={disabled || lesson.responses.length === 0}
                      onClick={() =>
                        setLessonSummary({
                          lessonName: lesson.name,
                          responses: lesson.responses,
                        })
                      }
                    >
                      {
                        lesson.responses.filter(
                          (response) => response.isCorrect === true
                        ).length
                      }{" "}
                      / {lesson.responses.length}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
          <Divider style={{ margin: "30px 0 15px 0" }} />
          <Typography gutterBottom color="secondary">
            Lessons still to do:
          </Typography>
          <Paper elevation={4}>
            <List dense>
              {nextLessons.length === 0 && (
                <ListItem>
                  <ListItemText primary="None" />
                </ListItem>
              )}
              {nextLessons.map((lesson, i) => (
                <ListItem
                  divider={i < nextLessons.length - 1}
                  key={`nextLessons_${i}`}
                  label={lesson.name}
                >
                  <ListItemIcon>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setVideo({
                          lesson: lesson._id,
                          lessonName: lesson.name,
                          video: lesson.video,
                          responses: lesson.responses,
                        })
                      }
                    >
                      <Videocam />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={lesson.name} />
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      // disabled={disabled || lesson.responses.length === 0}
                      style={{ textTransform: 'inherit' }}
                      component={NavLink}
                      to={`/initialSession/${(lesson || {})._id}`}
                    >
                      Questions
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              </List>
            </Paper>
        </Paper>
        <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
          <Typography gutterBottom color="secondary">
            Your next revision session
          </Typography>
          {(dueTopics || []).length > 0 && (
            <Typography paragraph>
              Please complete your{" "}
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ textTransform: "inherit" }}
                disabled={disabled}
                onClick={() => {
                  setDisabled(true);
                  axios({
                    url: "/sm2/createSession",
                    baseURL,
                    method: "post",
                    data: {},
                    headers: {
                      Authorization: token,
                    },
                  }).then((result) => {
                    /*console.log(
                      "createSession",
                      result.data,
                      `${window.location.origin}/revisionSession/${result.data._id}`
                    );*/
                    props.props.history.push(
                      `/revisionSession/${result.data._id}`
                    );
                  });
                }}
              >
                next revision session
              </Button>
            </Typography>
          )}
          {(dueTopics || []).length === 0 && (
            <Typography paragraph>
              You're up-to-date with your lessons.&nbsp; Well done!
            </Typography>
          )}
          <Divider style={{ margin: "10px 0 15px 0" }} />
          <Typography gutterBottom color="secondary">
            Your completed revision lessons:
          </Typography>
          <Paper elevation={4}>
            <List dense>
              {summary.revision.length === 0 && (
                <ListItem>
                  <ListItemText primary={`No revisions sessions yet`} />
                </ListItem>
              )}
              {summary.revision.map((session, i) => (
                <ListItem
                  key={`revision_li_${i}`}
                  divider={i < summary.revision.length - 1}
                >
                  <ListItemText primary={moment(session._id, 'YYYY-MM-DD').format('YYYY-MM-DD')} />
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={disabled}
                      onClick={() => setRevisionSummary(session)}
                    >
                      {
                        (session.responses || []).filter(
                          (response) => response.isCorrect === true
                        ).length
                      }{" "}
                      / {session.responses.length}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Paper>
      </Grid>
      <Dialog
        open={video !== null}
        maxWidth="lg"
        onClose={() => setVideo(null)}
      >
        <DialogTitle>
          {(video || {}).lessonName || "Video"}
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setVideo(null)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <VideoPlayer
            lesson={(video || {}).lesson}
            video={(video || {}).video}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={lessonSummary !== null}
        maxWidth="lg"
        onClose={() => setLessonSummary(null)}
      >
        <DialogTitle>
          Lesson Summary
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setLessonSummary(null)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Paper elevation={4}>
            <SessionSummary responses={(lessonSummary || {}).responses || []} />
          </Paper>
        </DialogContent>
      </Dialog>
      <Dialog
        open={revisionSummary !== null}
        maxWidth="lg"
        onClose={() => setRevisionSummary(null)}
      >
        <DialogTitle>
          Revision Summary
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setRevisionSummary(null)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <SessionSummary responses={((revisionSummary || {}).responses || [])} />
          {/*<Paper elevation={4}>
            <List dense>
              <ListItem divider>
                <ListItemText
                  primary={`Session: ${(revisionSummary || {})._id}`}
                  secondary={`${
                    ((revisionSummary || {}).responses || []).filter(
                      (response) => response.isCorrect === true
                    ).length
                  } / ${((revisionSummary || {}).responses || []).length}`}
                />
              </ListItem>
              {((revisionSummary || {}).responses || []).map((response, q) => (
                <ListItem
                  key={`resp_${q}`}
                  divider={
                    q < ((revisionSummary || {}).responses || []).length - 1
                  }
                >
                  <ListItemText
                    primary={`${q + 1}. ${response.questionText}`}
                  />
                  <ListItemSecondaryAction>
                    <Avatar
                      style={{
                        ...(response.isCorrect
                          ? { backgroundColor: "green" }
                          : { backgroundColor: "red" }),
                        ...{ width: "inherit", height: "inherit" },
                      }}
                    >
                      {response.isCorrect ? <Check /> : <Close />}
                    </Avatar>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>*/}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HomeCandidate;
