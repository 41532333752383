import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";

const onRedirectCallback = appState => {
  //console.log('onRedirectCallback', appState, (appState || {}).targetUrl)
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain="nmm-iw.eu.auth0.com"
    client_id="kJCwUqp0LOnzB015BmjTdiVC71PZXpab" // "232Gp1LKDOuxs5w2Uhuerguu4eFoZ4cu"
    audience="https://nmm-iw.eu.auth0.com/api/v2/" // "https://nmm.eu.auth0.com/api/v2/"
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    authorizeTimeoutInSeconds={10}
  >
    <App />
    </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
