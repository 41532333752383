import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
   Paper
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
//import { Table } from './../components/Table'
import { baseURL } from "./../globals";

const axios = require("axios");

const Lessons = () => {
    const { getTokenSilently } = useAuth0();
    const [lessons, setLessons] = useState([])
    useEffect(() => {
        getTokenSilently().then((token) => {
          axios({
            url: "/sm2/getUserLessons",
            baseURL,
            method: "post",
            data: {},
            headers: {
              Authorization: token,
            },
          })
          .then((result) => setLessons(result.data));
        });
      }, [getTokenSilently]);
      console.log('lessons', lessons)
    return (
<Container maxWidth="md" style={{ margin: "100px auto 0 auto" }}>
    <Typography variant="h6" color="secondary" align="center" paragraph>Lessons</Typography>
    <Paper elevation={4}>
    <List>
        {lessons.map((lesson, i) => (
            <ListItem key={`lesson_${i}`} button component={NavLink} to={`/lesson/${lesson._id}`} divider>
                <ListItemText primary={`${lesson.name}`} />
            </ListItem>
        ))}
    </List>
    </Paper>
</Container>
    )
}

export default Lessons;