export const baseURL = window.location.hostname === 'iw.nomoremarking.com' ? 'https://api-iw.nomoremarking.com' : 'https://dev-api-iw.nomoremarking.com'
export const videoTimeInterval = 10
export const dfe = 1234567
// export const arrDOBFormats = ["DD/MM/YY", "DD/MM/YYYY", "DD-MM-YYYY", "YYYY-MM-DD"]
export const fileDetails = [
    /*{
        label: "Code",
        key: "qrcode",
        alternates: ["QRCode"],
        required: false,
        validators: [
          {
            regex: /^$|^[A-Z0-9]{6}$/i,
            error: "Must be a valid code"
          }
        ]
      },*/
      /*{
        label: "Candidate",
        key: "Candidate",
        alternates: [],
        isRequired: false
      },*/
      {
        label: "First Name",
        key: "firstName",
        alternates: ["FirstName", "First_Name", "First Name"],
        isRequired: true
      },
      {
        label: "Last Name",
        key: "lastName",
        alternates: ["LastName", "Last_Name", "Last Name"],
        isRequired: true
      },
      {
        label: "Group",
        key: "group",
        alternates: ["className", "Class"],
        isRequired: true
      },
      {
        label: "Year Group",
        key: "yg",
        alternates: ["yearGroup", "grade", 'Year_Group', 'Year', 'yg', 'Year Group'],
        isRequired: true,
        validators: [
          {
            regex: /^\d{1,2}$|^year \d{1,2}$|^grade \d{1,2}$/i,// /^$|^(0|1|2|3|4|5|6|7|8|9|10|11|12|13)$/i,
            error: "Must be a positive integer"
          }
        ]
      },
      /*{
        label: "Date of Birth",
        key: "dob",
        alternates: ["DateOfBirth", "Date of Birth"],
        isRequired: true
      },*/
      {
        label: "Gender",
        key: "gender",
        alternates: ["sex"],
        isRequired: true,
        validators: [
          {
            regex: /^$|^(m|male|f|female)$/i,
            error: "Must be M or F"
          }
        ]
      },
      {
        label: "Pupil Premium",
        key: "PP",
        alternates: ["pupilPremium"],
        isRequired: false,
        validators: [
          {
            regex: /^$|^(1|0|y|n|yes|no|true|false|on|off|enabled|disabled)$/i,
            error: "Must be Y or N"
          }
        ]
      },
      {
        label: 'Email',
        key: 'email',
        alternates: ['Email Address'],
        isRequired: true,
        validators: [
          {
            // eslint-disable-next-line no-control-regex
            regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            error: 'Must be a valid email address',
          }
        ]
      },
      /*{
        label: "EAL",
        key: "EAL",
        alternates: ["ESL"],
        isRequired: false,
        validators: [
          {
            regex: /^$|^(1|0|y|n|yes|no|true|false|on|off|enabled|disabled)$/i,
            error: "Must be a Y or N"
          }
        ]
      }*/
]