import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
// import { Formik, Form, Field } from "formik";
import { Container, Paper, Typography, List, ListItem, ListItemText } from "@material-ui/core";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { TextField } from "formik-material-ui";
//import { DatePicker } from "formik-material-ui-pickers";
//import Autocomplete from "@material-ui/lab/Autocomplete";
// import { NavLink } from "react-router-dom";
import { baseURL } from "./../globals";
//import DateFnsUtils from "@date-io/date-fns";
import Footer from "./../components/Footer";

//const moment = require("moment");
const axios = require("axios");

const UserDetails = (props) => {
  const { getTokenSilently } = useAuth0();
  const [userDetails, setUserDetails] = useState(null);
  //const [open, setOpen] = useState(false);
  //const [options, setOptions] = useState([]);
  //const loading = open && options.length === 0;

  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/user/getUser",
        baseURL,
        method: "post",
        data: {},
        headers: {
          Authorization: token,
        },
      }).then((result) => setUserDetails(result.data));
    });
  }, [getTokenSilently]);

  if (!userDetails) return <span />;
  console.log("userD", userDetails);
  return (
    <>
    <Container
      fluid="true"
      maxWidth="md"
      style={{ margin: "100px auto 0 auto" }}
    >
      <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
        <Typography variant="h6" color="secondary" paragraph align="center">
          Select School
        </Typography>
        <Typography gutterBottom>Schools you are part of (click on to set your default):</Typography>
        <Paper elevation={4}>
        <List>
            {userDetails.dfes.map(dfe => (
                <ListItem key={`li_${dfe}`} divider button onClick={() => {
                    console.log('sel', dfe)
                    getTokenSilently().then((token) => {
                        axios({
                          url: "/user/updateSelSchool",
                          baseURL,
                          method: "post",
                          data: { dfe: dfe },
                          headers: {
                            Authorization: token,
                          },
                        }).then((result) => setUserDetails(result.data));
                    })
                }}>
                    <ListItemText primary={dfe} secondary={userDetails.selDfE === dfe ? 'Selected' : '-'} />
                </ListItem>
            ))}
        </List>
        </Paper>
      </Paper>
    </Container>
    <Footer />
    </>
  );
};

export default UserDetails;
