import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import { Container, Paper, Typography, Button, IconButton, Divider, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Delete, Close } from "@material-ui/icons"
import { Alert } from "@material-ui/lab";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//import { TextField } from "formik-material-ui";
import ReactTable from "react-table"; // './../components/Table'
import "react-table/react-table.css";
//import { DatePicker } from "formik-material-ui-pickers";
//import Autocomplete from "@material-ui/lab/Autocomplete";
// import { NavLink } from "react-router-dom";
import { baseURL } from "./../globals";
import Footer from "./../components/Footer";
//import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";

//const moment = require("moment");
const axios = require("axios");

const SchoolUsers = (props) => {
  const { getTokenSilently } = useAuth0();
  const [userDetails, setUserDetails] = useState(null);
  const [schoolUsers, setSchoolUsers] = useState([])
  const [open, setOpen] = useState(false);
  //const [options, setOptions] = useState([]);
  //const loading = open && options.length === 0;
  
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/user/getUser",
        baseURL,
        method: "post",
        data: {},
        headers: {
          Authorization: token,
        },
      }).then(result => {
          setUserDetails(result.data)
          axios({
            url: "/user/getSchoolUsers",
            baseURL,
            method: "post",
            data: { dfe: result.data.selDfE },
            headers: {
              Authorization: token,
            },
          }).then(result => {
            setSchoolUsers(result.data)
          });
      });
    });
  }, [getTokenSilently]);

  if (!userDetails) return <span />;
  console.log("userD", userDetails);
  const columns = [
      {
          Header: 'Email',
          accessor: 'email',
          Cell: cell => <div style={{ flex: 0, textAlign: "center" }}><a href={`mailto:${cell.value}`}>{cell.value}</a></div>
      },
      {
          accessor: '_id',
          width: 70,
          Cell: cell => <div style={{ flex: 0, textAlign: "center" }}><IconButton size="small" disabled={schoolUsers.length < 2} onClick={() => {
              console.log('del', cell.value)
              const c = window.confirm('Are you sure?\nThis cannot be undone')
              if (!c) return;
              getTokenSilently().then((token) => {
                axios({
                  url: "/user/deleteUser",
                  baseURL,
                  method: "post",
                  data: {
                      dfe: (userDetails || {}).selDfE,
                      user: cell.value,
                  },
                  headers: {
                    Authorization: token,
                  },
                }).then(result => {
                    setSchoolUsers(result.data)
                })
            })
          }}><Delete /></IconButton></div>
      }

  ]
  console.log('schoolUsers', schoolUsers)
  const dfe = (userDetails || {}).selDfE
  console.log('dfe', dfe)
  if (!dfe) return (
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Alert severity="info">The user is not connect to any school</Alert>
    </Container>
  )
  return (
    <>
    <Container
      fluid="true"
      maxWidth="md"
      style={{ margin: "100px auto 0 auto" }}
    >
      <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
        <Typography variant="h6" color="secondary" paragraph align="center">
            School Users for {(userDetails || {}).selDfE}
        </Typography>
        <Typography paragraph>You can add additional owners to your school using the table.</Typography>
        <Button size="small" variant="contained" color="primary" style={{ textTransform: 'inherit', margin: '0 0 20px 0' }} disabled={open} onClick={() => setOpen(true)}>Add Email</Button>
        <ReactTable columns={columns} data={schoolUsers} minRows={0} />
      </Paper>
      <Dialog open={open} maxWidth="lg" onClose={() => setOpen(false)}>
            <DialogTitle>
                Add User to School
                <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography gutterBottom>Enter the email address below:</Typography>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Must be a valid email")
                          .notOneOf(schoolUsers.map(user => user.email), 'Email already exists')
                          .required("Required"),
                      })}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log("onSubmit", values);
                        getTokenSilently().then((token) => {
                            axios({
                              url: "/user/addUser",
                              baseURL,
                              method: "post",
                              data: {
                                  dfe: (userDetails || {}).selDfE,
                                  email: values.email.toLowerCase(),
                              },
                              headers: {
                                Authorization: token,
                              },
                            }).then(result => {
                                setOpen(false)
                                setSchoolUsers(result.data)
                            })
                        })
                    }}
                    >
            {({ submitForm, isSubmitting, setFieldValue, errors, values }) => (
                <Form>
                    <FormikTextField name="email" label="Add Email" fullWidth />
                    <Button size="small" variant="contained" color="primary" type="submit" style={{ textTransform: 'inherit', margin: '20px 0 0 0' }} disabled={isSubmitting}>Submit</Button>
                </Form>
            )}
                    </Formik>
                </DialogContent>
      </Dialog>
    </Container>
    <Footer />
    </>
  );
};

export default SchoolUsers;
