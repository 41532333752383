import React, { useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import ReactPlayer from 'react-player'
import { baseURL, videoTimeInterval } from "./../globals";
const axios = require("axios");
// const moment = require('moment')

export const VideoPlayer = props => {
    const { getTokenSilently, user } = useAuth0();
    console.log('props player', props)
    const [duration, setDuration] = useState(null)
    const videoWidth = Math.min(window.innerWidth, 640);
    const videoHeight = (9 / 16) * videoWidth;
    if (!props) return <span />
    if (!props.lesson || !props.video) return <span />
  return (
          <ReactPlayer
            progressInterval={videoTimeInterval * 1000}
            onProgress={played => {
                getTokenSilently().then((token) => {
                axios({
                    url: '/sm2/recordPlayerProgress',
                    baseURL,
                    method: 'post',
                    data: {
                        lesson: props.lesson,
                        video: props.video,
                        videoDuration: duration,
                        timeInterval: videoTimeInterval,
                    },
                    headers: {
                        Authorization: token,
                      },
                })
            })
            }}
            onDuration={d => {
                if (!duration) {
                    setDuration(d)
                }
            }}
            url={`https://player.vimeo.com/video/${props.video}?user=${user.sub}`}
            config={{ vimeo: { playerOptions: { width: videoWidth, height: videoHeight, controls: true }}}}
        />
  );
};

export default VideoPlayer;