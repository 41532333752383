import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Grid, Button, Paper, Container, Stepper, Step, StepLabel, Card, CardHeader, Avatar, Divider, CardContent, Typography, Input, InputLabel, FormHelperText, FormControl, Select, MenuItem, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import {  Help, CloudUpload } from '@material-ui/icons'
import { Alert, AlertTitle } from "@material-ui/lab";
// import { NavLink } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import Papa from "papaparse";
import { baseURL, fileDetails } from './../globals'
import Footer from "./../components/Footer";
// import { Table } from './../components/Table'

const axios = require("axios")
// const moment = require('moment')
const _ = require('lodash')

const CandidatesUpload = props => {
  const { getTokenSilently } = useAuth0()
  const [activeStep, setActiveStep] = useState(0)
  const [msg, setMsg] = useState(<span />)
  const [csvColumns, setCsvColumns] = useState([])
  const [csvData, setCsvData] = useState([])
  const [fieldDetails, setFieldDetails] = useState(fileDetails)
  // const [disabled, setDisabled] = useState(false)
  const [existingCandidates, setExistingCandidates] =useState([])
  const [userDetails, setUserDetails] = useState(null)
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/user/getUser",
        baseURL,
        method: "post",
        data: {},
        headers: {
          Authorization: token,
        },
      }).then((result) => {
        setUserDetails(result.data)
        axios({
          url: "/candidates/getCandidates",
          baseURL,
          method: "post",
          data: { dfe: result.data.selDfE },
          headers: {
            Authorization: token,
          },
        }).then((result) => setExistingCandidates(result.data));
      })
    })
  }, [getTokenSilently])
  const requireMatching = _.map(
    _.filter(fieldDetails, { isRequired: true, csvCol: "ps" }),
    "label"
  );
  const keyMapping = fieldDetails.map(field => {
    return [field.key, field.csvCol];
  });
  const csvData2 = csvData.map(row => {
    let newRow = {};
    keyMapping.forEach(mapping => {
      newRow[mapping[0]] = row[mapping[1]];
    });
    return newRow;
  });
  /*const oDOBCounts = _.map(arrDOBFormats, dobFormat => {
    const count = _.sum(
      _.map(csvData2, row => {
        return moment(row.dob, dobFormat, true).isValid();
      })
    );
    return {
      format: dobFormat,
      count: count
    };
  });
  const dobFormat = _.first(_.orderBy(oDOBCounts, ["count"], ["desc"])).format;*/
  /*Yup.addMethod(Yup.object, "unique", function(propertyName, message) {
    return this.test("unique", message, function(value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      if (
        this.parent
          .filter(v => v !== value)
          .some(v => v[propertyName] === value[propertyName])
      ) {
        throw this.createError({
          path: `${this.path}.${propertyName}`
        });
      }

      return true;
    });
  });*/
  /*Yup.addMethod(Yup.string, "format", function() {
    return this.test(
      "test-dobFormat",
      `Invalid Date of Birth (${dobFormat})`,
      function(originalValue) {
        const value = moment(originalValue, dobFormat, true);
        return value.isValid();
      }
    );
  });
  Yup.addMethod(Yup.string, "ageCheck", function() {
    return this.test("test-name", "Invalid age (0-120)", function(
      originalValue
    ) {
      const min = moment().subtract(0, "years");
      const max = moment().subtract(120, "years");
      if (
        min.diff(moment(originalValue, dobFormat), "years") < 0 ||
        moment(originalValue, dobFormat).diff(max, "years") < 0
      )
        return false;
      return true;
    });
  });*/
  console.log('existingCandidates', existingCandidates)
  if (!userDetails) return <span />
  const dfe = (userDetails || {}).selDfE
  console.log('dfe', dfe)
  if (!dfe) return (
    <>
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Alert severity="info">The user is not connect to any school</Alert>
    </Container>
    <Footer />
    </>
  )
  return (
    <>
<Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
  <Typography variant="h6" color="secondary" paragraph align="center">Candidates Upload for {dfe}</Typography>
    <Grid container>
    <Paper elevation={4} style={{ width: "100%", padding: "15px 15px 15px 15px" }}>
        <Stepper activeStep={activeStep}>
              {["Upload", "Match Headers", "Repair Problems"].map(
                (stage, i) => (
                  <Step key={`step_${i}`}>
                    <StepLabel>{stage}</StepLabel>
                  </Step>
                )
              )}
            </Stepper>
            </Paper>
    </Grid>
    {activeStep === 0 && (
              <Grid
                container
                style={{ flexGrow: 1, width: "100%" }}
                justify="space-evenly"
              >
                <Grid
                  item
                  style={{ maxWidth: "50%", margin: "15px 15px 15px 15px" }}
                >
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar>
                          <CloudUpload />
                        </Avatar>
                      }
                      title="File Import"
                      subheader="Upload candidate details"
                    />
                    <Divider />
                    <CardContent>
                        {msg}
                      {false && (
                        <Alert severity="warning" style={{ marginBottom: '5px' }}>
                          Candidates have already been uploaded for this school,
                          are you sure you want to upload additional candidates?
                        </Alert>
                      )}
                      <FormControl>
                        <InputLabel htmlFor="idUploadCSV">File</InputLabel>
                        <Input
                          id="idUploadCSV"
                          type="file"
                          onChange={e => {
  
                            const file = e.target.files[0];
                            /*let reader = new FileReader();
                            reader.readAsBinaryString(file);
                            reader.onload = function() {
                              AxiosAll.post(`/aws/putFile`, {
                                key: `csv_uploads/${task}/${file.name}`,
                                body: reader.result
                              })
                                .then(result => {
                                  // console.log("put file", result);
                                })
                                .catch(error => {
                                  console.log("put file error", error);
                                });
                            };*/
                            Papa.parse(file, {
                              delimiter: true,
                              header: true,
                              dynamicTyping: false,
                              skipEmptyLines: true,
                              encoding: "UTF-8",
                              complete: function(results) {
                                console.log("results", results);
                                if (results.errors.length) {
                                  document.getElementById("idUploadCSV").value = null;
                                  setMsg(<Alert severity="error" style={{ marginBottom: '5px' }}>
                                  Error in file: ${results.errors[0].message}
                                </Alert>)
                                } else if (results.data.length === 0) {
                                    setMsg(<Alert severity="error" style={{ marginBottom: '5px' }}>Empty file</Alert>)
                                } else {
                                  console.log("fields", results.meta.fields, fieldDetails);
                                  const fieldDetails2 = _.map(fieldDetails, fieldDetail => {
                                    let arrPossibles = fieldDetail.alternates;
                                    arrPossibles.push(fieldDetail.key);
                                    const inter = _.intersectionWith(
                                      results.meta.fields,
                                      arrPossibles,
                                      (item1, item2) => {
                                        return item1.toLowerCase() === item2.toLowerCase();
                                      }
                                    );
                                    fieldDetail.csvCol = inter.length > 0 ? inter[0] : "ps";
                                    return fieldDetail;
                                  });
                                  setActiveStep(1)
                                  setCsvColumns(results.meta.fields)
                                  setCsvData(results.data)
                                  setFieldDetails(fieldDetails2)
                                }
                              }
                            });
                          }}
                        />
                        <FormHelperText>Select a CSV file</FormHelperText>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  style={{ maxWidth: "50%", margin: "15px 15px 15px 15px" }}
                >
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar>
                          <Help />
                        </Avatar>
                      }
                      title="Help"
                    />
                    <Divider />
                    <CardContent>Files must be in CSV format</CardContent>
                    <Divider />
                    <CardContent>
                      The first row must have header information
                    </CardContent>
                    <Divider />
                    <CardContent>
                      Download an example <a href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/IW_Template.csv">template file</a>
                    </CardContent>
                    {/*<CardContent>
                      PDFs will be generated in the same order that the file is
                      provided
                    </CardContent>*/}
                    {/*<Divider />
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/csv_Template.csv"
                        target="_blank"
                        style={{ textTransform: "inherit" }}
                      >
                        <ArrowDropDownCircle /> UK Template File
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/csv_TemplateUSA.csv"
                        target="_blank"
                        style={{ textTransform: "inherit" }}
                      >
                        <ArrowDropDownCircle /> USA Template File
                      </Button>
                    </CardActions>*/}
                  </Card>
                </Grid>
              </Grid>)}
              {activeStep === 1 && <Grid
                              container
                              style={{ flexGrow: 1, width: "100%" }}
                              justify="space-evenly"
              >
            <Paper elevation={4} style={{ width: "100%", padding: "15px" }}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ textTransform: "inherit", marginBottom: "5px" }}
                  disabled={requireMatching.length > 0}
                  onClick={() => setActiveStep(2)}
                >
                  Next Step
                </Button>
              </Grid>
              {requireMatching.length > 0 && (
                <Alert severity="error" style={{ marginBottom: "5px" }}>
                  <AlertTitle>Please ensure  that the mandatory fields: {requireMatching.join(", ")} are matched accurately</AlertTitle>
                  Mandatory values do not appear in the menu lists if already selected
                </Alert>
              )}
              <Typography gutterBottom><span style={{ color: '#ff0000' }}>*&nbsp;</span>Mandatory fields</Typography>
              {csvColumns.map((column, csvCol) => {
                const oFileDetail =
                  _.find(fieldDetails, fieldDetail => {
                    return (
                      (fieldDetail.csvCol || "").toLowerCase() ===
                      (column || "").toLowerCase()
                    );
                  }) || {};
                let sum = [];

                /*if (oFileDetail.key === "dob") {
                  sum = _.map(csvData, column).map(item =>
                    moment(item, dobFormat, true).isValid()
                  );
                } else */if (_.has(oFileDetail, "validators")) {
                  sum = _.map(csvData, column).map(item =>
                    oFileDetail.validators[0].regex.test(item)
                  );
                }
                let arrRows = []
                sum.forEach((valid, i) => {
                  if (!valid) arrRows.push(i + 2)
                })
                const sumCountBy = _.countBy(sum);
                const fCount = sumCountBy.false || 0;
                const tCount = sumCountBy.true || 0;
                return (
                  <Grid container key={`col_${csvCol}`}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Paper elevation={4} style={{ margin: "5px" }}>
                            <Table size="small">
                              <TableBody>
                                <TableRow
                                  style={{ backgroundColor: "#ededed" }}
                                >
                                  <TableCell align="center" style={{ width: '20%' }}>
                                    Column {csvCol + 1}
                                  </TableCell>
                                  <TableCell style={{ width: '40%' }}>{column}</TableCell>
                                  <TableCell style={{ width: '40%', backgroundColor: '#ffffff' }}>
                                    <Select
                                      id={`colSelect_${csvCol}`}
                                      defaultValue={oFileDetail.key || "ps"}
                                      fullWidth
                                      onChange={e => {
                                        const index = _.indexOf(_.map(fieldDetails, 'key'), e.target.value)
                                        /*console.log(
                                          "*",
                                          index,
                                          csvCol,
                                          e.target.value,
                                          column,
                                          fieldDetails.map(fieldDetail => (_.omit(fieldDetail, ['validators', 'isRequired']))),
                                        );*/
                                        //this.setState(prevState => ({ fieldDetails: { ...prevState.fieldDetails, ...prevState.fieldDetails[index].csvCol: column }}))
                                        let newFieldDetails = _.map(fieldDetails, (fieldDetail, j) => {
                                          if (j === index) fieldDetail.csvCol = column
                                          if (j !== index && fieldDetail.csvCol === column) fieldDetail.csvCol = 'ps'
                                          return fieldDetail
                                        })
                                        console.log('fieldDetails', fieldDetails)
                                        setFieldDetails(newFieldDetails)
                                      }}
                                    >
                                      <MenuItem value="ps">
                                        Please Select...
                                      </MenuItem>
                                      {_.filter(fieldDetails, row => (row.csvCol === 'ps' || row.csvCol === column)).map((oField, i) => (
                                        <MenuItem
                                          key={`mi_${csvCol}_${i}`}
                                          value={oField.key}
                                        >
                                          {oField.label}
                                          {oField.isRequired === true && <span style={{ color: '#ff0000' }}>&nbsp;*</span>}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                </TableRow>
                                {_.slice(csvData, 0, 3).map((row, j) => (
                                  <TableRow key={`row_${csvCol}_${j}`}>
                                    <TableCell
                                      align="center"
                                      style={{ backgroundColor: "#ededed" }}
                                    >
                                      {j + 2}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                      {_.values(row)[csvCol]}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={4}
                            style={{
                              margin: "5px",
                              padding: "5px 5px 5px 5px"
                            }}
                          >
                            {(oFileDetail.key || "ps") === "ps" && (
                              <Alert
                                severity="error"
                                style={{ margin: "5px 0 5px 0" }}
                              >
                                Field not matched
                              </Alert>
                            )}
                            {(oFileDetail.key || "ps") !== "ps" && (
                              <Alert
                                severity="success"
                                style={{ margin: "5px 0 5px 0" }}
                              >
                                Field matched
                              </Alert>
                            )}
                            {tCount + fCount > 0 && (
                              <Alert
                                severity={fCount === 0 ? "success" : "warning"}
                                style={{ margin: "5px 0 5px 0" }}
                              >
                                {((tCount / (tCount + fCount)) * 100).toFixed(
                                  1
                                )}
                                % have a valid row
                                {/*dobFormat === "" || column !== 'DOB'
                                  ? ""
                                : `(with dob format: ${dobFormat})`*/}
                                {arrRows.length > 0 && <>
                                  <br />
                                  Invalid Row(s): {arrRows.slice(0, 3).join(', ')}{arrRows.length > 3 && <> ...</>}
                                </>}
                              </Alert>
                            )}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>
                  </Grid>}
                  {activeStep === 2 && (
            <Paper elevation={4} style={{ width: "100%", padding: "15px" }}>
              <Formik
                initialValues={{ csvData: csvData2 }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  console.log("submit", values);
                  let oToImport = _.map(values.csvData, (row, i) => {
                    row.yg = parseInt(row.yg.replace(/^year |^grade /i, '').trim(), 10);
                    row.PP = row.PP || false;
                    row.EAL = row.EAL || false;
                    return row;
                  });
                  oToImport = _.filter(oToImport, oCand => {
                    oCand.gender = /f/i.test(oCand.gender) ? 'F' : 'M'
                    // oCand.dob = moment(oCand.dob, dobFormat).format('YYYY-MM-DD')
                    oCand.PP = /y/i.test(oCand.PP)
                    oCand.EAL = /y/i.test(oCand.EAL)
                    const toMatch = `${oCand.firstName ||
                      ""}_${oCand.lastName || ""}_${oCand.dob || ""}`;
                    const oFound = _.find(existingCandidates, {
                      toMatch: toMatch
                    });
                    return !oFound;
                  });
                  console.log("oToImport", oToImport);
                  props.history.push(`/candidates/edit`)
                  getTokenSilently().then((token) => {
                    axios({
                      url: "/candidates/createCandidates",
                      baseURL,
                      method: "post",
                      data: { dfe: (userDetails || {}).selDfE, candidates: oToImport },
                      headers: {
                        Authorization: token,
                      },
                    }).then((result) => props.history.push(`/candidates/edit`));
                 })
                  setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                  csvData: Yup.array().of(
                    Yup.object().shape({
                      firstName: Yup.string().required("Required"),
                      lastName: Yup.string().required("Required"),
                      group: Yup.string().required("Required"),
                      yg: Yup.string().matches(
                        /^\d{1,2}$|^year \d{1,2}$|^grade \d{1,2}$/i,
                        "Must be a positive integer"
                      ),
                      /*dob: Yup.string()
                        .format()
                        .ageCheck()
                        .required("Required"),*/
                      gender: Yup.string().matches(
                        /^$|^(m|male|f|female)$/i,
                        "Must be M or F"
                      ),
                      PP: Yup.string().matches(
                        /^$|^(1|0|y|n|yes|no|true|false|on|off|enabled|disabled)$/i,
                        "Must be Y or N"
                      ),
                      email: Yup.string().email('Email Required'),
                      /*EAL: Yup.string().matches(
                        /^$|^(1|0|y|n|yes|no|true|false|on|off|enabled|disabled)$/i,
                        "Must be a Y or N"
                      )*/
                    })
                  )
                })}
              >
                {({ isSubmitting, handleSubmit, values, errors }) => (
                  <Form>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                          textTransform: "inherit",
                          marginBottom: "5px"
                        }}
                        disabled={
                          !_.isEmpty(errors) || isSubmitting
                        }
                        type="submit"
                      >
                        Complete Upload
                      </Button>
                      {(errors.csvData || []).length > 0 && (
                        <Alert severity="error"  style={{ marginBottom: '5px' }}>
                          Please fix errors to continue
                        </Alert>
                      )}
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {fieldDetails.map((column, i) => (
                              <TableCell key={`col_${i}`} align="center">
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.csvData.map((row, i) => (
                            <TableRow key={`row_${i}`}>
                              {fieldDetails.map((column, j) => (
                                <TableCell key={`cell_${i}_${j}`}>
                                  <FormikTextField
                                    name={`csvData[${i}][${column.key}]`}
                                    fullWidth
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          )}
          {activeStep === 3 && (
            <Paper elevation={4} style={{ width: "100%", padding: "15px" }}>
              Complete
            </Paper>
          )}
</Container>
<Footer />
</>
  )
}

export default CandidatesUpload