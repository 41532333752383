import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {
  Button,
  Container,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Fab,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { Check, Close, Edit, Add, Delete, Email, Publish, GetApp } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { NavLink } from "react-router-dom";
import { baseURL } from "./../globals";
import Footer from "./../components/Footer";
import ReactTable from "react-table";
import "react-table/react-table.css";

const axios = require("axios");
const _ = require("lodash");
const FileSaver = require("file-saver");
const moment = require("moment");
const { Parser } = require('json2csv');

const CandidatesEdit = (props) => {
  const { user, getTokenSilently } = useAuth0();
  const [candidates, setCandidates] = useState(null);
  const [selCandidate, setSelCandidate] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [disabled, setDisabled] = useState(false)
  const [filter, setFilter] = useState([])
  const [msg, setMsg] = useState(<span />)
  console.log("user", user, userDetails);
  console.log('filter', filter)
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/user/getUser",
        baseURL,
        method: "post",
        data: {},
        headers: {
          Authorization: token,
        },
      }).then((result) => {
        setUserDetails(result.data);
        axios({
          url: "/candidates/getCandidates",
          baseURL,
          method: "post",
          data: { dfe: result.data.selDfE },
          headers: {
            Authorization: token,
          },
        }).then((result) => setCandidates(result.data));
      });
    });
  }, [getTokenSilently]);
  console.log("candidates", candidates);
  console.log("sel", selCandidate);
  const arrGroups = _.uniq(_.map(candidates, "group")).sort();
  const arrYGs = _.uniq(_.map(candidates, "yg")).sort();
  const columns = [
    {
      accessor: "_id",
      width: 120,
      filterable: false,
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>
          <IconButton
            size="small"
            disabled={disabled}
            onClick={() => setSelCandidate(row.row.values)}
            style={{ margin: "0 5px 0 0" }}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            disabled={disabled}
            style={{ margin: "0 5px 0 0" }}
            onClick={() => {
              const c = window.confirm(`Are you sure?\nCandidate responses will also be removed\nThis cannot be undone?`);
              if (!c) return;
              setDisabled(true)
              getTokenSilently().then((token) => {
                axios({
                  url: "/candidates/deleteCandidate",
                  baseURL,
                  method: "post",
                  data: {
                    dfe: (userDetails || {}).selDfE,
                    candidate: row.value,
                  },
                  headers: {
                    Authorization: token,
                  },
                }).then((result) => {
                  setCandidates(result.data)
                  setDisabled(false)
                  setMsg(<span />)
                });
              });
            }}
          >
            <Delete />
          </IconButton>
          <IconButton
            size="small"
            disabled={disabled}
            onClick={() => {
              setDisabled(true)
              getTokenSilently().then((token) => {
                console.log(">", row);
                axios({
                  url: "/auth0/passwordResets",
                  baseURL,
                  method: "post",
                  data: {
                    email: row.original.email,
                  },
                  headers: {
                    Authorization: token,
                  },
                }).then((result) => {
                  setDisabled(false)
                  setMsg(<Alert severity="info">Email password change sent to <strong>{row.original.email}</strong></Alert>)
                  console.log("password reset", result.data);
                });
              });
            }}
          >
            <Email />
          </IconButton>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>
          <a href={`mailto:${row.value}`}>{row.value}</a>
        </div>
      ),
      filterMethod: (filter, row) => (row[filter.id] || "").match(RegExp(filter.value, "i")),
    },
    {
      Header: <>First<br />Name</>,
      accessor: "firstName",
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>{row.value}</div>
      ),
      filterMethod: (filter, row) => (row[filter.id] || "").match(RegExp(filter.value, "i")),
    },
    {
      Header: <>Last<br />Name</>,
      accessor: "lastName",
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>{row.value}</div>
      ),
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
    },
    {
      Header: <>
        Group
        <Tooltip title="Send Password Reminder emails to all candidates or only in the selected group">
        <Fab
          size="small"
          style={{ margin: '0 0 5px 15px' }}
          disabled={disabled}
          onClick={() => {
            setDisabled(true)
            const group = filter.filter(r => r.id === 'group').length === 0 ? '' : filter.find(r => r.id === 'group').value
            const emails = candidates.filter(cand => RegExp(`^${group}$`).test(cand.group)).map(cand => cand.email)
            console.log('filter', filter, group, emails)
            getTokenSilently().then((token) => {
              emails.forEach((email, i) => {
                setTimeout(() => {
                  axios({
                    url: "/auth0/passwordResets",
                    baseURL,
                    method: "post",
                    data: {
                      email: email,
                    },
                    headers: {
                      Authorization: token,
                    },
                  })
                  if (i === emails.length - 1) {
                    setDisabled(false)
                    setMsg(<Alert severity="info">Email password change sent to <strong>{emails.length}</strong> user(s)</Alert>)
                  }
                }, i * 500)
              })
            })
          }}
        >
          <Email />
        </Fab>
        </Tooltip>
      </>,
      accessor: "group",
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>{row.value}</div>
      ),
      filterMethod: (filter, row) =>
        row[filter.id].match(RegExp(`^${filter.value}$`, "i")),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="">All</option>
          {arrGroups.map((group, i) => (
            <option key={`option_${i}`} value={group}>
              {group}
            </option>
          ))}
        </select>
      ),
    },
    {
      Header: (
        <span>
          YG
          <br />
          Grade
        </span>
      ),
      accessor: "yg",
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>{row.value}</div>
      ),
      width: 70,
      //filterMethod: (filter, row) => row[filter.id].match(RegExp(`^${filter.value}$`, "i")),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="">All</option>
          {arrYGs.map((group, i) => (
            <option key={`option_${i}`} value={group}>
              {group}
            </option>
          ))}
        </select>
      ),
    },
    {
      accessor: "PP",
      maxWidth: 70,
      Header: () => (
        <span>
          PP
          <br />
        </span>
      ),
      Cell: (row) => (
        <div style={{ flex: 0, textAlign: "center" }}>
          {row.value ? <Check /> : <Close />}
        </div>
      ),
      filterable: false,
    },
  ];
  if (!userDetails) return <span />;
  const dfe = (userDetails || {}).selDfE;
  console.log("dfe", dfe);
  if (!dfe)
    return (
      <>
      <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
        <Alert severity="info">The user is not connect to any school</Alert>
      </Container>
      <Footer />
      </>
    );
  return (
    <>
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Typography variant="h6" color="secondary" align="center" paragraph>
        Upload Class ({dfe})
      </Typography>
      <Typography variant="h6" color="secondary" align="center" paragraph>
        Student Details for {((userDetails || {}).School || {}).schoolName || '-'} ({dfe})
      </Typography>
      {msg}
      <Grid
        container
        style={{ margin: "20px 0 10px 0" }}
      >
        <Tooltip title="Upload CSV of student details">
          <Fab size="small" component={NavLink} to={`/candidates/upload`} style={{ margin: '0 15px 0 0' }}>
            <Publish />
          </Fab>
        </Tooltip>
        <Tooltip title="Add students manually">
        <Fab
          size="small"
          onClick={() => {
            setMsg(<span />)
            setSelCandidate({
              dfe: (userDetails || {}).selDfE,
              email: "",
              firstName: "",
              lastName: "",
              group: "",
              gender: "",
              yg: 1,
              PP: false,
            });
          }}
        >
          <Add />
        </Fab>
        </Tooltip>
        <span style={{ flex: 1 }} />
        <Tooltip title="Download students">
        <Fab
          size="small"
          onClick={() => {
            setMsg(<span />)
            console.log('*')
            const parser = new Parser({ fields: ['firstName', 'lastName', 'group', 'gender', 'PP', 'email'] })
            const csv = parser.parse(candidates)
            const blob = new Blob([csv], {
              type: "text/plain;charset=utf-8"
            });
            FileSaver.saveAs(blob, "students.csv");
          }}
        >
          <GetApp />
        </Fab>
        </Tooltip>
        {/*<Tooltip title="Send Password Reset email to all selected candidates">
        <Fab
          size="small"
          style={{ margin: '0 0 0 15px' }}
          onClick={() => {
          }}
        >
          <Email />
        </Fab>
        </Tooltip>
        <span style={{ flex: 1 }} />*/}
        {/*<Button size="small" variant="contained" color="primary" style={{ textTransform: 'inherit', margin: '0 7px 0 0' }} disabled={disabled} onClick={() => {
        setDisabled(true)
            getTokenSilently().then((token) => {
              axios({
                url: "/candidates/getSchoolDataLesson",
                baseURL,
                method: "post",
                data: { dfe: (userDetails || {}).selDfE },
                headers: {
                  Authorization: token,
                },
              }).then((result) => {
                console.log('getSchoolData', result.data)
                const blob = new Blob([result.data.join("\n")], {
                  type: "text/plain;charset=utf-8"
                });
                FileSaver.saveAs(blob, "lessons.csv");
                setDisabled(false)
              })
            })
      }}><GetApp /> Lesson</Button>
            <Button size="small" variant="contained" color="primary" style={{ textTransform: 'inherit' }} disabled={disabled} onClick={() => {
              setDisabled(true)
            getTokenSilently().then((token) => {
              axios({
                url: "/candidates/getSchoolDataRevision",
                baseURL,
                method: "post",
                data: { dfe: (userDetails || {}).selDfE },
                headers: {
                  Authorization: token,
                },
              }).then((result) => {
                console.log('getSchoolData', result.data)
                const blob = new Blob([result.data.join("\n")], {
                  type: "text/plain;charset=utf-8"
                });
                FileSaver.saveAs(blob, "revision.csv");
                setDisabled(false)
              })
            })
      }}><GetApp /> Revision</Button>*/}
      </Grid>

      <ReactTable columns={columns} data={candidates || []} filterable minRows={0} defaultPageSize={50} onFilteredChange={filter => setFilter(filter)} />
      {(candidates || []).length === 0 && (
        <Typography paragraph style={{ margin: "20px 0 0 0" }}>
          No Candidates, <NavLink to={`/candidates/upload`}>Upload</NavLink> to
          get started
        </Typography>
      )}
      <Dialog
        open={selCandidate !== null}
        maxWidth="lg"
        onClose={() => setSelCandidate(null)}
      >
        <DialogTitle>
          Add / Edit Candidate
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setSelCandidate(null)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography paragraph>
            Note email addresses cannot be updated.
          </Typography>
          <Formik
            initialValues={{
              ...selCandidate,
              ...{ PP: (selCandidate || {}).PP ? "Y" : "N" },
            }}
            validate={(values) => {
              let errors = {};
              console.log("validate", values);
              if ((values.email || "") === "") errors.email = "Required";
              if ((values.firstName || "") === "")
                errors.firstName = "Required";
              if ((values.lastName || "") === "") errors.lastName = "Required";
              if ((values.group || "") === "") errors.group = "Required";
              // if ((values.yg || '') === "") errors.yg = "Required";
              if (!/\b([1-9]|1[0-4])\b/.test(values.yg || ""))
                errors.yg = "Invalid Year Group (1-14)";
              // if ((values.PP || '') === "") errors.PP = "Required";
              if (!/^Y|N$/.test(values.PP)) errors.PP = "Must be Y or N";
              console.log("errors", errors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("onSubmit", values);
              getTokenSilently().then((token) => {
                axios({
                  url: "/candidates/updateCandidate",
                  baseURL,
                  method: "post",
                  data: {
                    ...values,
                    ...{
                      dfe: dfe,
                      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                      updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                    },
                  },
                  headers: {
                    Authorization: token,
                  },
                }).then((result) => {
                  setCandidates(result.data);
                  setSelCandidate(null);
                });
              });
            }}
          >
            {({ submitForm, isSubmitting, setFieldValue, errors, values }) => (
              <Form>
                <Field
                  component={TextField}
                  name="email"
                  label="Email Address"
                  disabled={(selCandidate || {}).email !== ""}
                  fullWidth
                />
                <Field
                  component={TextField}
                  name="firstName"
                  label="First Name"
                  fullWidth
                  xstyle={{ margin: "7px 0 7px 0" }}
                />
                <Field
                  component={TextField}
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  xstyle={{ margin: "7px 0 7px 0" }}
                />
                <Field
                  component={TextField}
                  name="group"
                  label="Group"
                  fullWidth
                  xstyle={{ margin: "7px 0 7px 0" }}
                />
                <Field
                  component={TextField}
                  name="yg"
                  label="Year Group"
                  fullWidth
                  xstyle={{ margin: "7px 0 7px 0" }}
                />
                <Field
                  component={TextField}
                  name="PP"
                  label="Pupil Premium"
                  fullWidth
                  xstyle={{ margin: "7px 0 7px 0" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  style={{ textTransform: "inherit", margin: "7px 0 7px 0" }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Container>
    {candidates !== null && <Footer />}
    </>
  );
};

export default CandidatesEdit;
