import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
// import Footer from "./components/Footer";
import Home from "./views/Home";
import Signup from './sm2/signup'
import UserDetails from './sm2/userDetails'
// import Lessons from "./views/Lessons";
// import Lesson from './views/Lesson'
import ProofReading from './views/proofReading'
// import Session from './views/Session'
// import Tasks from './views/tasks'
import DBUpdate from './sm2/DBUpdate'
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
// import MultipleChoiceSelect from './views/multipleChoiceSelect'
// import multipleChoice from './views/multipleChoice';
import Lessons from './sm2/lessons'
import Lesson from './sm2/lesson'
import Session from './sm2/session'
import Revision from './sm2/revision'
import Leaderboard from './sm2/leaderboard';
import CandidatesUpload from './sm2/candidatesUpload';
import CandidatesEdit from './sm2/candidatesEdit';
import SchoolUsers from './sm2/schoolUsers';
import TeacherProgress from './sm2/teacherProgress';
import Assignments from './sm2/assignments'
// import Topics from './sm2/topics'

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }
  return (
    <Router history={history}>
        <NavBar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/signup" exact component={Signup} />
            <PrivateRoute path="/leaderboard" exact component={Leaderboard} />
            <PrivateRoute path="/userDetails" component={UserDetails} />
            <PrivateRoute path="/schoolUsers" component={SchoolUsers} />
            <PrivateRoute path="/lessons" component={Lessons} />
            <PrivateRoute path="/lesson/:lesson" component={Lesson} />
            <PrivateRoute path="/proofReading/:question" component={ProofReading} />
            {/*<PrivateRoute path="/multipleChoiceSelect" component={MultipleChoiceSelect} />
            <PrivateRoute path="/multipleChoice" component={multipleChoice} />*/}
            {/*<PrivateRoute path="/session/:lesson/:revision" component={Session} />*/}
            <PrivateRoute path="/initialSession/:lesson" component={Session} />
            <PrivateRoute path="/revisionSession/:session" component={Session} />
            <PrivateRoute path="/revision" component={Revision} />
            <PrivateRoute path="/dbUpdate" component={DBUpdate} />
            <PrivateRoute path="/candidates/upload" component={CandidatesUpload} />
            <PrivateRoute path="/candidates/edit" component={CandidatesEdit} />
            <PrivateRoute path="/teacher/progress" component={TeacherProgress} />
            <PrivateRoute path="/teacher/assignments" component={Assignments} />
            {/*<PrivateRoute path="/teacher/topics" component={Topics} />*/}
          </Switch>
        {/*<Footer />*/}
    </Router>
  );
}

export default App;
