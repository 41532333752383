import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Container,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  TextField,
  ButtonGroup,
} from "@material-ui/core";
import { Close, Delete, Edit, Add } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { baseURL } from "./../globals";
//import Footer from "./../components/Footer";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Autocomplete } from "@material-ui/lab";

const axios = require("axios");
// const _ = require('lodash')
const moment = require('moment')

const Assignments = (props) => {
  const { getTokenSilently } = useAuth0();
  const [assignDialog, setAssignDialog] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [selLessons, setSelLessons] = useState([]);
  const [selGroup, setSelGroup] = useState(null);
  const [selDate, setSelDate] = useState(null);
  const [formOptions, setFormOptions] = useState({});
  const [dueDates, setDueDates] = useState(null);
  const [disabled, setDisabled] = useState(false)
  const [edit, setEdit] = useState(null)
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/user/getUser",
        baseURL,
        method: "post",
        data: {},
        headers: {
          Authorization: token,
        },
      }).then((result) => {
        setUserDetails(result.data);
        axios({
          url: "/teacher/getDueDates",
          baseURL,
          method: "post",
          data: { dfe: result.data.selDfE },
          headers: {
            Authorization: token,
          },
        }).then((result) => setDueDates(result.data));
        axios({
          url: "/teacher/getCreateAssignmentOptions",
          baseURL,
          method: "post",
          data: { dfe: result.data.selDfE },
          headers: {
            Authorization: token,
          },
        }).then((result) => setFormOptions(result.data));
      });
    });
  }, [getTokenSilently]);
  const dfe = (userDetails || {}).selDfE;
  if (userDetails === null || dueDates === null || formOptions === null)
    return <span />;
  const columns = [
    {
      accessor: '_id',
      filterable: false,
      width: 60,
      Cell: cell => (<div style={{ flex: 0, textAlign: "center" }}><IconButton size="small" onClick={() => {
        setEdit(cell.original)
        setSelDate(cell.original.dueDate)
      }}><Edit /></IconButton></div>)
    },
    {
      Header: "Lesson",
      accessor: "lessonName",
      Cell: (cell) => (
        <div style={{ flex: 0, textAlign: "center" }}>{cell.value}</div>
      ),
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
    },
    {
      Header: "Group",
      accessor: "group",
      Cell: (cell) => (
        <div style={{ flex: 0, textAlign: "center" }}>{cell.value}</div>
      ),
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
      Cell: (cell) => (
        <div style={{ flex: 0, textAlign: "center" }}>{cell.value}</div>
      ),
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
    },
    {
      accessor: '_id',
      filterable: false,
      width: 60,
      Cell: cell => (<div style={{ flex: 0, textAlign: "center" }}>
        <IconButton size="small" disabled={disabled || cell.original.responses > 0} onClick={() => {
          // console.log('delete due date', cell.value)
          const c = window.confirm(`Delete Task?`)
          if (!c) return
          setDisabled(true)
          getTokenSilently().then((token) => {
          axios({
            url: "/teacher/deleteAssignment",
            baseURL,
            method: "post",
            data: {
              dfe: dfe,
              id: cell.value
            },
            headers: {
              Authorization: token,
            },
          }).then((result) => {
            setDueDates(result.data)
            setDisabled(false)
          });
        })
        }}>
        <Delete />
        </IconButton>
        </div>)
    }
  ];
  // console.log('selDate', selDate)
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Typography variant="h6" color="secondary" align="center" paragraph>
        Set Task ({dfe})
      </Typography>
      <Typography gutterBottom>You cannot delete a task after a student has responded.</Typography>
      <ButtonGroup size="small" variant="contained" color="primary" style={{ float: "right" }}>
        <Button disabled style={{ color: 'black' }}><Add /></Button>
        <Button style={{ textTransform: 'inherit' }} onClick={() => setAssignDialog(true)}>Set Task</Button>
      </ButtonGroup>
      <Grid container style={{ padding: "10px 0 0 0" }}>
        <Typography>Tasks you've already set:</Typography>
        <ReactTable
          columns={columns}
          data={dueDates}
          minRows={0}
          filterable
          style={{ width: "100%" }}
        />
      </Grid>
      <Dialog
        open={assignDialog}
        maxWidth="lg"
        onClose={() => setAssignDialog(false)}
      >
        <DialogTitle>
          Set Task
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setAssignDialog(false)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Autocomplete
            multiple
            size="small"
            options={formOptions.lessons}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              console.log('lessons', event, newValue)
              setSelLessons(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Lessons" variant="standard" />
            )}
            style={{ margin: "10px 0 10px 0" }}
          />
          <Autocomplete
            size="small"
            options={formOptions.groups}
            getOptionLabel={(option) => option._id}
            onChange={(event, newValue) => {
              setSelGroup(newValue._id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Group" variant="standard" />
            )}
            style={{ margin: "10px 0 10px 0" }}
          />
          {/*<TextField
            size="small"
            variant="outlined"
            // type="date"
            label="Due Date (YYYY-MM-DD)"
            defaultValue=""
            fullWidth
            onChange={(e) => setSelDate(e.target.value)}
          />*/}
          <KeyboardDatePicker variant="outlined" format="dd/MM/yyyy" margin="dense" label="Due Date" fullWidth value={selDate} onChange={v => setSelDate(v)} />
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ textTransform: "inherit", margin: "10px 0 10px 0" }}
            fullWidth
            disabled={
              selLessons.length === 0 || selGroup === null || selDate === null // || /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(selDate) === false
            }
            onClick={() => {
              getTokenSilently().then((token) => {
                const arrProms = selLessons.map(selLesson => {
                  return axios({
                    url: "/teacher/assignDueDates",
                    baseURL,
                    method: "post",
                    data: {
                      dfe: dfe,
                      lesson: selLesson._id,
                      group: selGroup,
                      dueDate: moment(selDate).format('YYYY-MM-DD'),
                    },
                    headers: {
                      Authorization: token,
                    },
                  })
                })
                Promise.all(arrProms)
                .then(result => {
                  axios({
                    url: "/teacher/getDueDates",
                    baseURL,
                    method: "post",
                    data: { dfe: dfe },
                    headers: {
                      Authorization: token,
                    },
                  })
                  .then(result => {
                    console.log('getDueDates', result.data)
                      setAssignDialog(false);
                      setDueDates(result.data)
                  })
                })
              });
            }}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={edit !== null} maxWidth="lg" onClose={() => setEdit(null)}>
            <DialogTitle>
              Edit
              <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setEdit(null)}
          >
            <Close />
          </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <TextField margin="dense" variant="standard" label="Lesson" value={(edit || {}).lessonName} disabled fullWidth />
              <TextField margin="dense" variant="standard" label="Group" value={(edit || {}).group} disabled fullWidth />
              <KeyboardDatePicker
                variant="standard"
                format="dd/MM/yyyy"
                margin="dense"
                label="Due Date"
                fullWidth
                value={selDate}
                onChange={v => setSelDate(v)}
              />
              {/*<TextField
            margin="dense"
            variant="outlined"
            type="date"
            label="Due Date"
            defaultValue={(edit || {}).dueDate}
            onChange={(e) => setSelDate(e.target.value)} fullWidth
              />*/}
                    <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ textTransform: "inherit", margin: "10px 0 10px 0" }}
            fullWidth
            disabled={selDate === null}
            onClick={() => {
              getTokenSilently().then((token) => {
                axios({
                  url: "/teacher/assignDueDates",
                  baseURL,
                  method: "post",
                  data: {
                    dfe: dfe,
                    lesson: edit.lesson,
                    group: edit.group,
                    dueDate: moment(selDate).format('YYYY-MM-DD'), // selDate,
                  },
                  headers: {
                    Authorization: token,
                  },
                }).then((result) => {
                  axios({
                    url: "/teacher/getDueDates",
                    baseURL,
                    method: "post",
                    data: { dfe: dfe },
                    headers: {
                      Authorization: token,
                    },
                  })
                  .then(result => {
                    setEdit(null);
                    setDueDates(result.data)
                  })
                });
              });
            }}
          >
            Update
          </Button>
            </DialogContent>
      </Dialog>
    </Container>
    </MuiPickersUtilsProvider>
  );
};

export default Assignments;
