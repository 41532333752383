import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {
  Container,
  Typography,
  Button,
} from "@material-ui/core";
//import { NavLink } from "react-router-dom";
// import { Alert } from "@material-ui/lab";
/*import {
  Close, Check,
} from "@material-ui/icons";*/
import { baseURL } from "./../globals";

const axios = require("axios");

const Revision = (props) => {
  console.log('Revision', props)
    const { getTokenSilently } = useAuth0();
    const [lessons, setLessons] = useState([])
    const [mostRecent, setMostRecent] = useState(null)
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        getTokenSilently().then((token) => {
          axios({
            url: "/sm2/getUserLessons",
            baseURL,
            method: "post",
            data: {},
            headers: {
              Authorization: token,
            },
          })
          .then((result) => {
              setLessons(result.data)
              let tmp = null
              result.data.forEach(row => {
                  if (row.initialDone === true) tmp = row._id
              })
              setMostRecent(tmp)
          });
        });
      }, [getTokenSilently]);
      console.log('lessons', lessons)
      console.log('mostRecent', mostRecent)
      if (lessons.length === 0) return <span />
      if (mostRecent === null) return (
        <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
          <Typography>You do not appear to have completed any of the initial questions for any lesson.&nbsp; Please complete these before beginning revision.</Typography>
        </Container>
      )
    return (
        <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
        <Typography variant="h6" color="secondary" align="center" paragraph>
          Revision
          </Typography>
            <Button size="small" variant="contained" color="primary" /*component={NavLink} to={`/session/${mostRecent}/1`}*/ disabled={disabled} style={{ textTransform: 'inherit' }} onClick={() => {
              setDisabled(true)
              getTokenSilently().then((token) => {
                axios({
                  url: "/sm2/createSession",
                  baseURL,
                  method: "post",
                  data: {},
                  headers: {
                    Authorization: token,
                  },
                })
                .then((result) => {
                  // console.log('push', result.data)
                  props.history.push(`/revisionSession/${result.data._id}`)
                  setDisabled(false)

                })
              })

              
            }}>Start Revision</Button>
          </Container>
    )
}

export default Revision
    
