import React, { useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import {
  Container,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
//import { Delete, Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
//import { NavLink } from "react-router-dom";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//import { TextField } from "formik-material-ui";
//import ReactTable from "react-table"; // './../components/Table'
//import "react-table/react-table.css";
//import { DatePicker } from "formik-material-ui-pickers";
//import Autocomplete from "@material-ui/lab/Autocomplete";
// import { NavLink } from "react-router-dom";
import { baseURL } from "./../globals";
import * as Yup from "yup";

//const moment = require("moment");
const axios = require("axios");

const Signup = (props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [activeStep, setActiveStep] = useState(0);
  const [termsChecked, setTermsChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    personFirstName: "",
    personJobTitle: "",
    personLastName: "",
    personPhone: "",
    schoolName: "",
    dfe: "",
  });
  //console.log("isAuthenticated", isAuthenticated);
  if (isAuthenticated)
    return (
      <Container
        fluid="true"
        maxWidth="md"
        style={{ margin: "100px auto 0 auto" }}
      >
        <Alert severity="info">
          You must not be logged in to complete this form
        </Alert>
      </Container>
    );
  return (
    <Container
      fluid="true"
      maxWidth="md"
      style={{ margin: "100px auto 0 auto" }}
    >
      <Typography variant="h6" color="secondary" align="center" paragraph>
        Sign up
      </Typography>
      <Paper elevation={4}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
          <Step>
            <StepLabel>Summary</StepLabel>
          </Step>
        </Stepper>
      </Paper>
      {activeStep === 0 && (
        <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
          <Typography paragraph>
            If you would like to subscribe your school to{" "}
            <strong>Improving Writing</strong> please use this form.&nbsp; This
            form should only be completed once for each school, the signed up
            user can add additional accounts.
          </Typography>
          <Formik
            initialValues={formValues}
            onSubmit={(values, { setSubmitting }) => {
              console.log("submit values", values);
              setActiveStep(1);
              setFormValues(values);
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d!@#$%^&*]{8,}$/, 'Must be at least 8 characters, one lower case, one upper case, one number and one special character').required('Required'),
              personFirstName: Yup.string().required("Required"),
              personLastName: Yup.string().required("Required"),
              personJobTitle: Yup.string().required("Required"),
              personPhone: Yup.string().required("Required"),
              dfe: Yup.number()
                .min(1000000, "Must be a seven digit number")
                .max(9999999, "Must be a seven digit number")
                .required("Required"),
              schoolName: Yup.string().required("Required"),
            })}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <Paper elevation={4}>
                  <Typography
                    paragraph
                    variant="h6"
                    style={{ padding: "15px 0 0 15px" }}
                  >
                    Account
                  </Typography>
                  <Grid container justify="space-around">
                    <Grid item style={{ width: "45%" }}>
                      <FormikTextField
                        label="Your Email Address"
                        name="email"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "1" }}
                        validate={async (value) => {
                          if (
                            value === "" ||
                            /^\S+@\S+\.\S+$/.test(value) === false
                          )
                            return;
                          const result = await axios({
                            url: "/noauth/accountExists",
                            baseURL,
                            method: "post",
                            data: { email: value },
                            /*headers: {
                                  Authorization: token,
                                },*/
                          });
                          //.then(result => console.log('account exists', value, result.data))

                          return result.data; //'Invalid email address'
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "45%" }}>
                      <FormikTextField
                        type="password"
                        label="Password"
                        name="password"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "2" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={4}>
                  <Typography
                    paragraph
                    variant="h6"
                    style={{ padding: "15px 0 0 15px" }}
                  >
                    Personals
                  </Typography>
                  <Grid container justify="space-around">
                    <Grid item style={{ width: "45%" }}>
                      <FormikTextField
                        label="First Name"
                        name="personFirstName"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "3" }}
                      />
                      <FormikTextField
                        label="Job Title"
                        name="personJobTitle"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "5" }}
                      />
                    </Grid>
                    <Grid item style={{ width: "45%" }}>
                      <FormikTextField
                        label="Last Name"
                        name="personLastName"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "4" }}
                      />
                      <FormikTextField
                        label="Phone"
                        name="personPhone"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "6" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={4}>
                  <Typography
                    paragraph
                    variant="h6"
                    style={{ padding: "15px 0 0 15px" }}
                  >
                    School
                  </Typography>
                  <Grid container justify="space-around">
                    <Grid item style={{ width: "45%" }}>
                      <FormikTextField
                        type="number"
                        label="DfE Number"
                        name="dfe"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "7" }}
                        validate={async (value) => {
                          const res = await axios({
                            url: "/noauth/schoolExists",
                            baseURL,
                            method: "post",
                            data: { dfe: value },
                          });
                          return res.data ? `School already signed up` : false;
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "45%" }}>
                      <FormikTextField
                        label="School Name"
                        name="schoolName"
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        inputProps={{ tabIndex: "8" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "inherit",
                      margin: "15px 0 15px 15px",
                    }}
                  >
                    Submit
                  </Button>
                </Paper>
              </Form>
            )}
          </Formik>
        </Paper>
      )}
      {activeStep === 1 && (
        <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
          <Typography paragraph variant="h6" gutterBottom>
            Review
          </Typography>

          <List>
            <ListItem>
              <ListItemText primary="Main Contact" />
              <Typography align="right">
                {formValues.personFirstName} {formValues.personLastName}
                <br />
                {formValues.personJobTitle}
                <br />
                {formValues.email}
                <br />
                {formValues.personPhone}
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="School" />
              <Typography align="right">
                DfE number: {formValues.dfe}
                <br />
                {formValues.schoolName}
              </Typography>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    name="terms"
                    onChange={(e) => setTermsChecked(e.target.checked)}
                  />
                }
                label={
                  <Typography>
                    <a
                      href="https://s3-eu-west-1.amazonaws.com/nmm-v2/terms/GDPR+agreement.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      I accept the terms & conditions
                    </a>
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ textTransform: "inherit" }}
            disabled={!termsChecked || disabled}
            onClick={() => {
              console.log("complete", formValues);
              setDisabled(true);
              axios({
                url: "/noauth/doSignup",
                baseURL,
                method: "post",
                data: { values: formValues },
                /*headers: {
                      Authorization: token,
                    },*/
              }).then((result) => {
                setActiveStep(2);
              });
            }}
          >
            Complete Order
          </Button>
        </Paper>
      )}
      {activeStep === 2 && (
        <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
          <Typography paragraph variant="h6" gutterBottom>
            Summary
          </Typography>
          <Typography gutterBottom>Thank you for your subscription.</Typography>
          <Typography gutterBottom>
            You will shortly be sent a confirmation email with your next steps.
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ textTransform: "inherit", margin: "20px 0 0 0" }}
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
        </Paper>
      )}
    </Container>
  );
};
export default Signup;
