import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {
  Grid,
  Paper,
 Button, Typography,  Container
} from "@material-ui/core";
// import { Delete, ArrowRightAlt, Check, Close } from '@material-ui/icons'
//import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
//import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { withRouter, NavLink } from "react-router-dom";
//import ReactPlayer from 'react-player'
import { VideoPlayer } from './../components/Player'
import { baseURL } from "./../globals";
const axios = require("axios");

const Lesson = (props) => {
    const { getTokenSilently } = useAuth0();
    const lessonid = props.match.params.lesson;
    console.log('lessonid', lessonid)
    const [lesson, setLesson] = useState(null)
    useEffect(() => {
        getTokenSilently().then((token) => {
          axios({
            url: "/sm2/getLesson",
            baseURL,
            method: "post",
            data: { lesson: lessonid },
            headers: {
              Authorization: token,
            },
          })
          .then((result) => setLesson(result.data));
        });
      }, [getTokenSilently, lessonid]);
      
      if (lesson === null) return <Typography>Loading...</Typography>
      console.log('lesson', lesson)
    return (
<Container maxWidth="md" style={{ margin: "100px auto 0 auto" }}>
    <Typography variant="h6" color="secondary" align="center" paragraph>{lesson.name}</Typography>
    <>
        <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
            <Grid container justify="center">
            <VideoPlayer lesson={lesson._id} video={lesson.video} />
            </Grid>
        </Paper>
    </>
    <>
        <Button size="small" variant="contained" color="primary" style={{ textTransform: 'inherit', margin: '15px 0 0 0' }} component={NavLink} to={`/initialSession/${lessonid}`}>Do Initial Questions</Button>
    </>
</Container>
    )
}

export default withRouter(Lesson);