import React from "react";
import { Grid, Button } from "@material-ui/core";

const Footer = () => (
  <>
    <Grid
      container
      justify="space-between"
      style={{ backgroundColor: "#3f51b5", margin: "30px 0 0 0" }}
    >
      {/*<Grid item style={{ margin: "30px auto 30px auto" }}>
        <Typography style={{ color: "#ffffff" }}>
          <span style={{ fontWeight: "bold" }}>No More Marking Ltd</span>
          <br />
          26 Bridgemere Drive
          <br />
          Durham DH1 5FG
        </Typography>
      </Grid>*/}
      <Grid item style={{ margin: "10px auto 10px auto" }}>
        {/*<a
          href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354"
          target="_blank"
          rel="noopener noreferrer"
        >*/}
          <Button style={{ textTransform: 'inherit', color: '#ffffff' }} href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354" target="_blank" rel="noopener noreferrer">Support</Button>
          {/*<Typography style={{ color: "#ffffff" }}>Support</Typography>*/}
        {/*</a>*/}
      </Grid>
    {/*</Grid>
    <Grid
      container
      justify="space-between"
      style={{ backgroundColor: "#3f51b5" }}
    >*/}
      {/*<Grid item style={{ margin: "0 auto 30px auto" }}>
        <a
          href="https://s3-eu-west-1.amazonaws.com/nmm-v2/terms/GDPR+agreement.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography style={{ color: "#ffffff" }}>
            Terms & Conditions
          </Typography>
        </a>
    </Grid>*/}
      <Grid item style={{ margin: "10px auto 10px auto" }}>
      <Button disabled style={{ textTransform: 'inherit', color: '#ffffff', fontSize: 'smaller' }}>Copyright &copy; 2020 No More Marking All Rights Reserved</Button>
        {/*<Typography style={{ color: "#ffffff" }}>
          Copyright &copy; 2020 No More Marking All Rights Reserved
  </Typography>*/}
      </Grid>
    </Grid>
  </>
);

export default Footer;
