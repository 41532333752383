import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Button, Menu, MenuItem } from "@material-ui/core";
import { ExpandMore, Refresh } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { baseURL } from "./../globals";

const axios = require("axios");

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [anchorCandsEl, setAnchorCandsEl] = useState(null)
  //console.log('navbar')
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently,
  } = useAuth0();
  //console.log("isAuthenticated", isAuthenticated);
  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently()
        .then((token) => {
          // console.log('home token', token)
          axios({
            url: "/user/getUser",
            baseURL,
            method: "post",
            data: {},
            headers: {
              Authorization: token,
            },
          }).then((result) => setUserDetails(result.data));
        })
        .catch((err) => setUserDetails(err));
    }
  }, [isAuthenticated, getTokenSilently]);
  const open = Boolean(anchorEl);
  // const openCands = Boolean(anchorCandsEl)
  // if (!userDetails) return <span />

  // const hasUserDetails = (userDetails || {}).school !== null;
  //console.log("hasUserDetails", hasUserDetails);
  //console.log("userDetails", userDetails);
  return (
    <>
      <AppBar>
        <Toolbar>
          <Button color="inherit" component={NavLink} to={`/`}>
            Home
          </Button>
          {/*isAuthenticated && <Button color="inherit" component={NavLink} to={`/leaderboard`}>
            Leaderboard
          </Button>*/}
          {/*isAuthenticated && (
            <Button color="inherit" component={NavLink} to={`/lessons`}>
              Lessons
            </Button>
          )*/}
          {/*isAuthenticated && hasUserDetails && (
            <Button
              color="inherit"
              component={NavLink}
              to={`/multipleChoice`}
            >
              Flags
            </Button>
          )*/}
          {isAuthenticated && (userDetails || {}).type === 'admin' && (
            <Button
              color="inherit"
              component={NavLink}
              to={`/candidates/edit`}
            >
              Upload Class
            </Button>
          )}
                    {isAuthenticated && (userDetails || {}).type === 'admin' && (
            <Button
              color="inherit"
              component={NavLink}
              to={`/teacher/assignments`}
            >
              Set Task
            </Button>
          )}
                    {isAuthenticated && (userDetails || {}).type === 'admin' && (
            <Button
              color="inherit"
              component={NavLink}
              to={`/teacher/progress`}
            >
              Check Progress
            </Button>
          )}

                    {/*isAuthenticated && (userDetails || {}).type === 'admin' && (
            <Button
              color="inherit"
              component={NavLink}
              to={`/teacher/topics`}
            >
              Topics
            </Button>
                    )*/}
                    {/*isAuthenticated && (userDetails || {}).type === 'admin' && (
            <>
              <Button
                color="inherit"
                onClick={(e) => setAnchorCandsEl(e.currentTarget)}
              >
                Candidates<ExpandMore />
              </Button>
              <Menu
                anchorEl={anchorCandsEl}
                open={openCands}
                onClose={() => setAnchorCandsEl(null)}
              >
                <MenuItem component={NavLink} to={`/candidates/upload`} onClick={() => setAnchorCandsEl(null)}>
                  Upload
                </MenuItem>
                <MenuItem component={NavLink} to={`/candidates/edit`}  onClick={() => setAnchorCandsEl(null)}>
                  View / Edit
                </MenuItem>
              </Menu>
            </>
                    )*/}
          <span style={{ flex: 1 }} />
          {!isAuthenticated && (
            <Button color="inherit" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          )}
          {!isAuthenticated && (
            <Button color="inherit" component={NavLink} to={`/signup`}>
              Signup
            </Button>
          )}
          {isAuthenticated && (userDetails || {}).type === 'admin' && (
            <Button color="inherit" component={NavLink} to={`/DBUpdate`}>
              <Refresh />
            </Button>
          )}
          {isAuthenticated && (
            <>
              <Button
                color="inherit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                style={{ textTransform: "inherit" }}
              >
                {user.email} <ExpandMore />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {(userDetails || {}).type === 'admin' && (<MenuItem component={NavLink} to={`/userDetails`} onClick={() => setAnchorEl(null)}>
                  Select School
                </MenuItem>)}
                {(userDetails || {}).type === 'admin' && (<MenuItem component={NavLink} to={`/schoolUsers`} onClick={() => setAnchorEl(null)}>
                  School Users
                </MenuItem>)}
                <MenuItem
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin,
                    })
                  }
                >
                  Log out
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
