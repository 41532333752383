import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {
  Container,
  Typography,
  Grid,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Paper
} from "@material-ui/core";
import { Close, Videocam } from "@material-ui/icons";
// import { Chart } from "react-google-charts";
//import { NavLink } from "react-router-dom";
import { baseURL } from "./../globals";
//import Footer from "./../components/Footer";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { GetApp } from "@material-ui/icons";
// import { Autocomplete } from "@material-ui/lab";
import SessionSummary from "./../sm2/sessionSummary";
import VideoPlayer from "../components/Player";

const axios = require("axios");
const _ = require("lodash");
const moment = require("moment");
const FileSaver = require("file-saver");

const TeacherProgress = (props) => {
  const { getTokenSilently } = useAuth0();
  const [progress, setProgress] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [candidateLesson, setCandidateLesson] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [video, setVideo] = useState(null);
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/user/getUser",
        baseURL,
        method: "post",
        data: {},
        headers: {
          Authorization: token,
        },
      }).then((result) => {
        setUserDetails(result.data);
        axios({
          url: "/teacher/getTeacherAssignments",
          baseURL,
          method: "post",
          data: { dfe: result.data.selDfE },
          headers: {
            Authorization: token,
          },
        }).then((result) => setProgress(result.data));
      });
    });
  }, [getTokenSilently]);
  if (progress === null || userDetails === null) return <span />;
  const dfe = (userDetails || {}).selDfE;
  const arrGroups = _.uniq(_.map(progress.teacherAssignments, "group"));
  // console.log('sel', selGroup, selLesson, selDate)
  //console.log("dfe", dfe);
  //console.log("arrGroups", arrGroups);
  console.log("progress", progress);
  //console.log("userDetails", userDetails);
  //console.log("lessons", progress.lessons);
  console.log("candidateLesson", candidateLesson);
  const teacherAssignments = progress.teacherAssignments.map((row) => {
    let lessonContents = {};
    progress.lessons.forEach((lesson, i) => {
      const candidateLesson = row[`lesson_${i + 1}`] || {};
      /*const isDue =
        "dueDate" in candidateLesson
          ? moment().isAfter(candidateLesson.dueDate, "day")
          : false;*/
      // console.log('**', row, candidateLesson, isDue)
      let stage = {
        color: "gray",
        text: "-",
      };
      const set = "dueDate" in candidateLesson;
      const completed =
        candidateLesson.responses === candidateLesson.lessonInitialQuestions;
      const overdue = set
        ? moment().isAfter(candidateLesson.dueDate, "day")
        : false;
      if (set === true) {
        if (overdue === true) {
          // set & not overdue
          if (completed === true) {
            // set & not overdue & completed
            stage = {
              color: "red",
              text: `${candidateLesson.correct || 0} / ${
                candidateLesson.lessonInitialQuestions
              }`,
            };
          } else {
            // set & not overdue & not completed
            stage = {
              color: "green",
              text: `${candidateLesson.correct || 0} / ${
                candidateLesson.lessonInitialQuestions
              }`,
            };
          }
        } else {
          // set & overdue
          if (completed === true) {
            // set & not overdue & completed
            stage = {
              color: "green",
              text: `${candidateLesson.correct || 0} / ${
                candidateLesson.lessonInitialQuestions
              }`,
            };
          } else {
            // set & overdue & not completed
            stage = {
              color: "red",
              text: `Due ${moment(candidateLesson.dueDate, "YYYY-MM-DD").format(
                "DD/MM/YY"
              )}`,
            };
          }
        }
      } else {
        // not set
        if (overdue === true) {
          // not set & not overdue
          if (completed === true) {
            // not set & not overdue & completed
            stage = {
              color: "gray",
              text: "not possible1",
            };
          } else {
            // not set & overdue & not completed
            stage = {
              color: "gray",
              text: "not possible2",
            };
          }
        } else {
          // not set & overdue
          if (completed === true) {
            // not set & not overdue & completed
            stage = {
              color: "gray",
              text: "not possible3",
            };
          } else {
            // not set & not overdue & not completed
            stage = {
              color: "gray",
              text: "Not set",
            };
          }
        }
      }
      stage = { ...stage, score: candidateLesson.correct || 0 };
      lessonContents = { ...lessonContents, ...{ [`lesson_${i + 1}`]: stage } };
    });
    return { ...row, ...lessonContents };
  });

  console.log("teacherAssignments", teacherAssignments);
  const columns = [
    {
      Header: <small>Group</small>,
      accessor: "group",
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="">All</option>
          {arrGroups.map((group, i) => (
            <option value={group || ""} key={`group_${i}`}>
              {group}
            </option>
          ))}
        </select>
      ),
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
      Cell: (cell) => (
        <div style={{ flex: 0, textAlign: "center" }}>{cell.value}</div>
      ),
    },
    {
      Header: <small>First<br />Name</small>,
      accessor: "firstName",
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
      Cell: (cell) => (
        <div style={{ flex: 0, textAlign: "center" }}>
          <small>{cell.value}</small>
        </div>
      ),
    },
    {
      Header: <small>Last<br />Name</small>,
      accessor: "lastName",
      filterMethod: (filter, row) =>
        (row[filter.id] || "").match(RegExp(filter.value, "i")),
      Cell: (cell) => (
        <div style={{ flex: 0, textAlign: "center" }}>
          <small>{cell.value}</small>
        </div>
      ),
    },
  ].concat(
    progress.lessons.map((lesson, i) => ({
      Header: (
        <div style={{ whiteSpace: "normal" }}>
          <small>{lesson.name}</small><br />
          <IconButton size="small" onClick={() => setVideo({ lesson: lesson._id, lessonName: lesson.name, video: lesson.video, responses: [] })}><Videocam /></IconButton>
        </div>
      ),
      accessor: `lesson_${i + 1}`,
      filterable: false,
      width: 75,
      Cell: (cell) => {
        return (
          <div style={{ flex: 0, textAlign: "center", whiteSpace: "normal" }}>
            {cell.value.score > 0 && (
              <Button
                size="small"
                variant="contained" //"outlined"
                // color="primary"
                //style={{ color: cell.value.color }}
                style={{ backgroundColor: cell.value.color, color: 'white' }}
                onClick={() => {
                  //console.log('*', lesson, cell.original)
                  getTokenSilently().then((token) => {
                    axios({
                      url: "/teacher/getCandidateLessonResponses",
                      baseURL,
                      method: "post",
                      data: {
                        user: cell.original.user,
                        lesson: lesson._id,
                      },
                      headers: {
                        Authorization: token,
                      },
                    }).then((result) => {
                      setCandidateLesson(result.data);
                    });
                  });
                }}
              >
                {cell.value.text}
              </Button>
            )}
            {cell.value.score === 0 && (
              <small style={{ color: cell.value.color }}>
                {cell.value.text}
              </small>
            )}
          </div>
        );
      },
    }))
  ).concat({
    Header: <small>Revision<br />Sessions</small>,
    accessor: 'sessions',
    filterable: false,
    width: 70,
    Cell: cell => <div style={{ flex: 0, textAlign: "center", whiteSpace: "normal" }}>{cell.value}</div>
  });
  return (
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Typography variant="h6" color="secondary" align="center" paragraph>
        Check Progress ({dfe})
      </Typography>
      <Typography variant="h6" color="secondary" align="center" paragraph>
        Student Progress at {((userDetails || {}).School || '-').schoolName} ({dfe})
      </Typography>
      <Grid container justify="center">
        <span style={{ flex: 1 }} />
        <ButtonGroup size="small" variant="contained" color="primary">
          <Button disabled style={{ color: "black" }}>
            <GetApp />
          </Button>
          <Button
            style={{ textTransform: "inherit" }}
            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              let csv = teacherAssignments.map((row) => {
                return [row.group, row.firstName, row.lastName]
                  .concat(
                    progress.lessons.map(
                      (lesson, i) => row[`lesson_${i + 1}`].score
                    )
                  )
                  .join(", ");
              });
              let columnTitles = ["Group", "First Name", "Last Name"]
                .concat(progress.lessons.map((lesson) => lesson.name))
                .join(", ");
              csv = [columnTitles, ...csv];
              console.log("csv", csv);
              const blob = new Blob([csv.join("\n")], {
                type: "text/plain;charset=utf-8",
              });
              FileSaver.saveAs(blob, "lessons.csv");
              setDisabled(false);
              /*getTokenSilently().then((token) => {
              axios({
                url: "/candidates/getSchoolDataLesson",
                baseURL,
                method: "post",
                data: { dfe: dfe },
                headers: {
                  Authorization: token,
                },
              }).then((result) => {
                console.log("getSchoolDataLesson", result.data);
                const blob = new Blob([result.data.join("\n")], {
                  type: "text/plain;charset=utf-8",
                });
                FileSaver.saveAs(blob, "lessons.csv");
                setDisabled(false);
              });
            });*/
            }}
          >
            Lessons
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid container style={{ padding: "10px 0 0 0" }}>
        <ReactTable
          columns={columns}
          data={teacherAssignments}
          minRows={0}
          filterable
          style={{ width: "100%" }}
        />
      </Grid>
      {/*<Dialog open={assignDialog} maxWidth="lg" onClose={() => setAssignDialog(false)}>
            <DialogTitle>
                Create Assignemnt Deadline
                <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setAssignDialog(false)}
          >
            <Close />
          </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Autocomplete
                    size="small"
                    options={progress.lessons}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValue) => {
                        setSelLesson(newValue._id)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Lesson" variant="outlined" />
                      )}
                      style={{ margin: '10px 0 10px 0' }}
                />
                <Autocomplete
                    size="small"
                    options={arrGroups}
                    //getOptionLabel={option => option.name}
                    onChange={(event, newValue) => {
                        setSelGroup(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Group" variant="outlined" />
                      )}
                      style={{ margin: '10px 0 10px 0' }}
                />
                <TextField size="small" variant="outlined" type="date" label="Due Date" defaultValue="" onChange={e => setSelDate(e.target.value)} />
                <Button size="small" variant="contained" color="primary" style={{ textTransform: 'inherit', margin: '10px 0 10px 0' }} fullWidth disabled={selLesson === null || selGroup === null || selDate === null} onClick={() => {
                            getTokenSilently().then((token) => {
                                axios({
                                  url: "/teacher/assignDueDates",
                                  baseURL,
                                  method: "post",
                                  data: {
                                      dfe: dfe,
                                      lesson: selLesson,
                                      group: selGroup,
                                      dueDate: selDate,
                                  },
                                  headers: {
                                    Authorization: token,
                                  },
                                }).then((result) => {
                                    setAssignDialog(false)
                                })
                            })
                }}>Submit</Button>
            </DialogContent>
              </Dialog>*/}
      <Dialog
        open={candidateLesson !== null}
        onClose={() => setCandidateLesson(null)}
      >
        <DialogTitle>
          Responses
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setCandidateLesson(null)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Paper elevation={4} xstyle={{ padding: "15px 15px 15px 15px" }}>
            <SessionSummary responses={candidateLesson || []} />
          </Paper>
        </DialogContent>
      </Dialog>
      <Dialog
        open={video !== null}
        maxWidth="lg"
        onClose={() => setVideo(null)}
      >
        <DialogTitle>
          {(video || {}).lessonName || "Video"}
          <IconButton
            size="small"
            style={{ float: "right" }}
            onClick={() => setVideo(null)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <VideoPlayer
            lesson={(video || {}).lesson}
            video={(video || {}).video}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TeacherProgress;
