import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {
  Grid,
  Paper,
  Container,
  Typography,
  Button,
  IconButton,
  Avatar,
LinearProgress
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Close, Check } from "@material-ui/icons";
import { baseURL } from "./../globals";
import SessionSummary from "./sessionSummary";

const axios = require("axios");
// const _ = require('lodash')

const isCorrect = (answers, selected) => {
  let correct = 0;
  let wrong = 0;
  answers.forEach((answer) => {
    if (answer[0] === "*") {
      if (selected.includes(answer)) {
        correct++;
      } else {
        wrong++;
      }
    } else {
      if (selected.includes(answer)) {
        wrong++;
      }
    }
  });
  return correct > 0 && wrong === 0;
};

const Session = (props) => {
  const lessonid = props.match.params.lesson || null;
  const sessionid = props.match.params.session || null;
  const { getTokenSilently } = useAuth0();
  const [lesson, setLesson] = useState(null);
  const [nextQuestion, setNextQuestion] = useState(null);
  const [selected, setSelected] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    getTokenSilently().then((token) => {
      axios({
        url: "/sm2/getLesson",
        baseURL,
        method: "post",
        data: { lesson: lessonid },
        headers: {
          Authorization: token,
        },
      }).then((result) => setLesson(result.data));
      if (sessionid !== null) {
        // Revision
        axios({
          url: "/sm2/getNextQuestionRevision",
          baseURL,
          method: "post",
          data: {
            session: sessionid,
          },
          headers: {
            Authorization: token,
          },
        }).then((result) => setNextQuestion(result.data));
        //})
      } else {
        // Initial
        axios({
          url: "/sm2/getNextQuestionInitial",
          baseURL,
          method: "post",
          data: {
            lesson: lessonid,
          },
          headers: {
            Authorization: token,
          },
        }).then((result) => setNextQuestion(result.data));
      }
    });
  }, [getTokenSilently, sessionid, lessonid]);
  if (nextQuestion === null) return <Typography>Loading ...</Typography>;
  console.log("lesson", lesson);
  console.log("nq", nextQuestion);
  console.log("selected", selected);
  console.log('next Question', nextQuestion, 'completed' in nextQuestion, 'remaining' in nextQuestion, Math.round(nextQuestion.completed / (nextQuestion.completed + nextQuestion.remaining) * 100))
  const multipleCorrect =
    (nextQuestion.mcOptions || []).reduce(
      (total, item) => (item[0] === "*" ? total + 1 : total),
      0
    ) > 1;
  console.log("multipleCorrect", multipleCorrect);
  if (nextQuestion.finished === true) {
    const responses = nextQuestion.responses || [];
    console.log("responses", responses);
    return (
      <Container maxWidth="md" style={{ margin: "100px auto 0 auto" }}>
        <Paper elevation={4} xstyle={{ padding: "15px 15px 15px 15px" }}>
          <SessionSummary responses={responses} />
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ textTransform: "inherit", margin: "15px 15px 15px 15px" }}
            component={NavLink}
            to={`/`}
          >
            Back to Home
          </Button>
        </Paper>
      </Container>
    );
  }
  return (
    <Container maxWidth="lg" style={{ margin: "100px auto 0 auto" }}>
      <Typography variant="h6" color="secondary" align="center">
        {sessionid !== null
          ? "Revision Questions"
          : `${(lesson || {}).name}`}
        <IconButton
          size="small"
          style={{ float: "right", margin: "0 40px 0 0" }}
          onClick={() => {
            const r = window.confirm(
              "Are you sure\nyou want to leave the session?"
            );
            if (!r) return;
            props.history.push("/");
          }}
        >
          <Close />
        </IconButton>
      </Typography>
      <Container
        maxWidth="lg"
        fluid="true"
        style={{ margin: "30px auto 0 auto" }}
      >
        {/*<Grid container>*/}
        <Container
          maxWidth="lg"
          fluid="true"
          style={{ margin: "0px auto 0 auto" }}
        >
        {'completed' in nextQuestion && 'remaining' in nextQuestion && <LinearProgress color="secondary" value={Math.round(nextQuestion.completed / (nextQuestion.completed + nextQuestion.remaining) * 100)} variant="determinate" style={{ width: '100%' }} />}
          <Paper elevation={4} style={{ padding: "15px 15px 15px 15px" }}>
            <Typography variant="h5" color="primary" gutterBottom>
              {nextQuestion.completed + 1}. {nextQuestion.name}{" "}
              <span style={{ fontSize: "12px", float: "right", color: "gray" }}>
                {nextQuestion.topicName}
              </span>
            </Typography>
            <Typography variant="h6" color="secondary">
              {nextQuestion.questionText}
            </Typography>
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                justify="space-around"
                alignItems="stretch"
                direction={window.innerWidth < 700 ? "column" : "row"}
                style={{ margin: "50px 0 50px 0" }}
              >
                {nextQuestion.mcOptions.map((option, i) => {
                  let backgroundColor = `#3f51b5`;
                  if (selected.includes(option) && !submitted)
                    backgroundColor = `#3f1f71`;
                  if (submitted && option[0] === "*") backgroundColor = `green`;
                  if (
                    selected.length > 0 &&
                    submitted &&
                    option[0] !== "*" &&
                    selected.includes(option)
                  )
                    backgroundColor = `red`;
                  return (
                    <Grid
                      item
                      xs
                      key={`option_${i}`}
                      style={{ padding: "10px 10px 10px 10px" }}
                    >
                      <Button
                        variant="contained"
                        //color={selected === option ? 'secondary' : 'primary'}
                        style={{
                          textTransform: "inherit",
                          width: "100%",
                          height: "100%",
                          backgroundColor: backgroundColor,
                          color: "white",
                        }}
                        onClick={() => {
                          if (submitted) return;
                          //console.log("resp", option, i, (multipleCorrect === true), selected.includes(option));
                          if (selected.includes(option)) {
                            if (multipleCorrect === true) {
                              setSelected(selected.filter((s) => s !== option));
                            }
                          } else {
                            if (multipleCorrect === true) {
                              //console.log('mc', multipleCorrect, option)
                              setSelected((oldArray) => [...oldArray, option]);
                            }
                            else {
                              setSelected([option])
                            }
                              
                          }
                        }}
                      >
                        {option.replace(/^\*/, "")}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Paper>
        </Container>
        <Container
          maxWidth="lg"
          fluid="true"
          style={{ margin: "0px auto 0 auto", padding: "15px 15px 15px 15px" }}
        >
          <Paper
            elevation={4}
            style={{
              margin: "7px 7px 7px 7px",
              padding: "15px 15px 15px 15px",
            }}
          >
            {selected === null && (
              <Grid container>
                <Typography>Please select a response above</Typography>
              </Grid>
            )}
            {selected !== null && submitted === false && (
              <Grid container>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "inherit" }}
                  disabled={selected.length === 0}
                  onClick={() => {
                    getTokenSilently().then((token) => {
                      if (sessionid !== null) {
                        // Revision
                        console.log("recordResponseRevision", nextQuestion._id);
                        axios({
                          url: "/sm2/recordResponseRevision",
                          baseURL,
                          method: "post",
                          data: {
                            session: sessionid,
                            lesson: lessonid,
                            question: nextQuestion._id,
                            selected: selected,
                            topicName: nextQuestion.topicName,
                          },
                          headers: {
                            Authorization: token,
                          },
                        }).then((result) => setSubmitted(true));
                      } else {
                        //Initial
                        axios({
                          url: "/sm2/recordResponseInitial",
                          baseURL,
                          method: "post",
                          data: {
                            lesson: lessonid,
                            question: nextQuestion._id,
                            selected: selected,
                            topicName: nextQuestion.topicName,
                          },
                          headers: {
                            Authorization: token,
                          },
                        }).then((result) => setSubmitted(true));
                      }
                    });
                  }}
                >
                  Submit
                </Button>
              </Grid>
            )}
            {selected.length > 0 && submitted === true && (
              <Grid container justify="flex-start">
                {isCorrect(nextQuestion.mcOptions, selected) ===
                  true /*selected[0] === '*'*/ && (
                  <>
                    <Avatar style={{ color: "#000", backgroundColor: "#fff" }}>
                      <Check fontSize="large" />
                    </Avatar>
                    <Typography
                      paragraph
                      display="inline"
                      variant="h5"
                      style={{ margin: "5px 0 0 0" }}
                    >
                      Correct
                    </Typography>
                  </>
                )}
                {isCorrect(nextQuestion.mcOptions, selected) ===
                  false /*selected[0] !== '*'*/ && (
                  <>
                    <Avatar style={{ color: "#000", backgroundColor: "#fff" }}>
                      <Close fontSize="large" />
                    </Avatar>
                    <Typography
                      paragraph
                      display="inline"
                      variant="h5"
                      style={{ margin: "5px 0 0 0" }}
                    >
                      Wrong
                    </Typography>
                  </>
                )}
                <Grid container>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ textTransform: "inherit", margin: "20px" }}
                    onClick={() => {
                      console.log("next question");
                      getTokenSilently().then((token) => {
                        if (sessionid !== null) {
                          // Revision
                          axios({
                            url: "/sm2/getNextQuestionRevision",
                            baseURL,
                            method: "post",
                            data: {
                              lesson: lessonid,
                              session: sessionid,
                            },
                            headers: {
                              Authorization: token,
                            },
                          }).then((result) => {
                            setNextQuestion(result.data);
                            setSelected([]);
                            setSubmitted(false);
                          });
                        } else {
                          // Initial
                          axios({
                            url: "/sm2/getNextQuestionInitial",
                            baseURL,
                            method: "post",
                            data: {
                              lesson: lessonid,
                            },
                            headers: {
                              Authorization: token,
                            },
                          }).then((result) => {
                            setNextQuestion(result.data);
                            setSelected([]);
                            setSubmitted(false);
                          });
                        }
                      });
                    }}
                  >
                    Next Question
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Container>
      </Container>
    </Container>
  );
};

export default Session;
